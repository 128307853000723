<template>
    <div class="container">
        <navbar title="预约" :leftArrow="false" bgColor="#F7F7F7"></navbar>
        <div class="booking">
            <div class="wrap">
                <div class="book_item">
                    <h2 class="book_title font-30 color-3">在线报修</h2>
                    <ul class="book_list flex flex-wrap">
                        <li>
                            <router-link class="book_link" to="/repair">
                                <h3 class="book_tag font-20 color-m"><span>户内</span></h3>
                                <div class="book_img">
                                    <img src="@/assets/icon/booking_01.png" alt="">
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="book_link" to="/booking_form?type=2">
                                <h3 class="book_tag font-20 color-m"><span>公共</span></h3>
                                <div class="book_img">
                                    <img src="@/assets/icon/booking_02.png" alt="">
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="book_item">
                    <h2 class="book_title font-30 color-3">预约保洁</h2>
                    <ul class="book_list flex flex-wrap">
                        <li>
                            <router-link class="book_link" to="/booking_form?type=3">
                                <h3 class="book_tag font-20 color-m"><span>开荒</span></h3>
                                <div class="book_img">
                                    <img src="@/assets/icon/booking_03.png" alt="">
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link class="book_link" to="/booking_form?type=4">
                                <h3 class="book_tag font-20 color-m"><span>日常</span></h3>
                                <div class="book_img">
                                    <img src="@/assets/icon/booking_04.png" alt="">
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="book_item">
                    <h2 class="book_title font-30 color-3">车位预约</h2>
                    <ul class="book_list flex flex-wrap">
                        <li>
                            <router-link class="book_link" to="/booking_form?type=5">
                                <h3 class="book_tag font-20 color-m"><span>车位</span></h3>
                                <div class="book_img">
                                    <img src="@/assets/icon/booking_05.png" alt="">
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="book_item">
                    <h2 class="book_title font-30 color-3">进出门申请</h2>
                    <ul class="book_list flex flex-wrap">
                        <li>
                            <router-link class="book_link" to="/booking_form?type=6">
                                <h3 class="book_tag font-20 color-m"><span>进出</span></h3>
                                <div class="book_img">
                                    <img src="@/assets/icon/booking_06.png" alt="">
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="book_item">
                    <h2 class="book_title font-30 color-3">其他服务</h2>
                    <ul class="book_list flex flex-wrap">
                        <li>
                            <router-link class="book_link" to="/booking_form?type=7">
                                <h3 class="book_tag font-20 color-m"><span>其他</span></h3>
                                <div class="book_img">
                                    <img src="@/assets/icon/booking_06.png" alt="">
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <Tabbar :current="1"></Tabbar>
    </div>
</template>
<script>
import Tabbar from '@/components/Tabbar.vue'
import {mapGetters} from 'vuex'
export default {
    name: 'booking',
    components: { Tabbar },
    data () {
        return {
        }
    },
    computed: {
        ...mapGetters(['token'])
    },

    methods: {
    },
    created () {
        if (!this.token) {
            this.$toast.fail('你还未登录')
            setTimeout(function() {
                window.location.href = '/login'
            }, 500)
            return false
        }
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    .booking{
        padding-bottom: .3rem;
    }
    .book_item{
        .book_title{
            line-height: 1.6;
            margin: .2rem 0;
        }
        .book_list{
            margin: 0 -.15rem;
            li{
                width: 50%;
                padding: .1rem .15rem;
            }
            .book_link{
                display: block;
                padding: .3rem;
                background-color: #F0F7FF;
                border-radius: .16rem;
            }
            .book_tag{
                margin: 0 -.1rem;
                span{
                    display: inline-block;
                    vertical-align: middle;
                    min-width: .72rem;
                    line-height: .36rem;
                    padding: 0 .16rem;
                    margin: 0 -.1rem;
                    margin-bottom: .2rem;
                    border-radius: .22rem;
                    background-color: #D7EAFF;
                    text-align: center;
                    font-weight: normal;
                }
            }
            .book_img{
                width: 1.92rem;
                height: 2.32rem;
                margin: .1rem auto;
                border-radius: .16rem;
                overflow: hidden;;
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
</style>