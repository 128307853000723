<template>
    <div class="container">        
        <navbar title="缴费收据" bgColor="#F7F7F7"></navbar>
        <div class="receipt">
            <div class="wrap">
                <div class="receipt_detail">
                    <img class="receipt_icon" src="@/assets/icon/receipt.png" alt="">
                    <h2 class="receipt_type font-28 color-6 text-center">物业费</h2>
                    <div class="receipt_price font-48 color-m text-center">¥3218.56</div>
                    <van-cell-group class="receipt_bot">
                        <van-cell title="缴费类型" value="物业费缴纳" />
                        <van-cell title="缴费时间" value="2023-2-13" />
                    </van-cell-group>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'receipt',
    data () {
        return {
        }
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    .receipt{
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }
    .receipt_detail{
        line-height: 1.6;
        margin: 1.4rem 0 .3rem;
        background-color: #FFFFFF;
        border-radius: .16rem;
        .receipt_icon{
            display: block;
            width: 1rem;
            height: 1rem;
            margin: 0 auto;
            transform: translateY(-.34rem);
        }
        .receipt_type{
            line-height: 1.6;
            font-weight: normal;
        }
    }
    .receipt_bot{
        padding: .2rem 0;
        background-color: transparent;
        &::after{
            display: none;
        }
        .van-cell{
            font-size: .26rem;
            color: #999999;
            line-height: 2;
            padding: .1rem .3rem;
            background-color: transparent;
            &::after{
                display: none;
            }
        }
        .van-cell__value{
            color: #666666;
        }
    }
</style>