import request, { get } from '@/utils/request'
// 登录
export function login(mobile, captcha) {
    return request({
        url: '/wechat/auth',
        method: 'post',
        data: {
            mobile,
            captcha
        }
    })
}

export function getinfo() {
    return get('/wechat/info')
}
export function logout() {
    return request({
        url: '/user/login_out',
        method: 'post'
    })
}

export function updatePassword(password, new_password, reset_new_password) {
    return request({
        url: `/user/edit_password`,
        method: 'post',
        data: { password, new_password, reset_new_password }
    })
}
// 用户详细信息
export default {
    getdetailinfo(params) {
        return get('user/user_details', { params })
    }
}
