<template>
    <div class="container">        
        <navbar title="消息" :leftArrow="false" bgColor="#F7F7F7"></navbar>
        <div class="tab_bg tab_bg2">
            <div class="wrap">
                <van-tabs 
                    v-model="active"
                    color="#4E9DF6"
                    background="transparent"
                    line-width=".56rem"
                    line-height=".06rem"
                    title-active-color="#4E9DF6"
                    title-inactive-color="#666666"
                    :ellipsis="false"
                    class="tab_list"
                    @click="tabChange"
                >
                    <van-tab title="全部" name="1"></van-tab>
                    <van-tab title="报修消息" name="2"></van-tab>
                    <van-tab title="预约消息" name="3"></van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="list">
            <div class="wrap">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    class="notice_list"
                >
                    <div class="notice_item">
                        <router-link class="notice_link font-24 color-6" to="/property_news_detail">
                            <div class="notice_top">
                                <h3 class="notice_tit font-28 color-0">最新消息，您收到一条报修消息</h3>
                                <p class="ellipsis">您的订单39247270972当前已发货，请您及时查收。</p>
                            </div>
                            <div class="notice_bot color-9 flex justify-between align-center">
                                时间：2021-10-10 10:00:00
                                <van-icon name="arrow" color="#D6D6D6" />
                            </div>
                        </router-link>
                    </div>
                    <div class="notice_item">
                        <router-link class="notice_link font-24 color-6" to="/property_news_detail">
                            <div class="notice_top">
                                <h3 class="notice_tit font-28 color-0">最新消息，您收到一条报修消息</h3>
                                <p class="ellipsis">您的订单39247270972当前已发货，请您及时查收。</p>
                            </div>
                            <div class="notice_bot color-9 flex justify-between align-center">
                                时间：2021-10-10 10:00:00
                                <van-icon name="arrow" color="#D6D6D6" />
                            </div>
                        </router-link>
                    </div>
                    <div class="notice_item">
                        <router-link class="notice_link font-24 color-6" to="/property_news_detail">
                            <div class="notice_top">
                                <h3 class="notice_tit font-28 color-0">最新消息，您收到一条报修消息</h3>
                                <p class="ellipsis">您的订单39247270972当前已发货，请您及时查收。</p>
                            </div>
                            <div class="notice_bot color-9 flex justify-between align-center">
                                时间：2021-10-10 10:00:00
                                <van-icon name="arrow" color="#D6D6D6" />
                            </div>
                        </router-link>
                    </div>
                </van-list>
            </div>
        </div>
        <TabbarProperty :current="1"></TabbarProperty>
    </div>
</template>
<script>
import TabbarProperty from '@/components/TabbarProperty.vue'
export default {
    name: 'bill',
    components: { TabbarProperty },
    data () {
        return {
            active: 2,
            list: [],
            loading: false,
            finished: false,
        }
    },
    methods: {
        // 切换
        tabChange(name) {
            this.$toast(name)
        },
        onLoad() {
            
        }
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.list{
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}
.notice_list{
    list-style: none;
    overflow: hidden;
    .notice_item{
        position: relative;
        margin-bottom: .3rem;
        &::before{
            display: block;
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: .9rem;
            height: .9rem;
            background-position: right top;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &.over::before{
            background-image: url('~@/assets/icon/over.png');
        }
        &.pending::before{
            background-image: url('~@/assets/icon/pending.png');
        }
        &.appraise::before{
            background-image: url('~@/assets/icon/appraise.png');
        }
        &.confirm::before{
            background-image: url('~@/assets/icon/confirm.png');
        }
        &.failed::before{
            background-image: url('~@/assets/icon/failed.png');
        }
        &.passed::before{
            background-image: url('~@/assets/icon/passed.png');
        }
        .notice_link{
            display: block;
            background: #FFFFFF;
            border-radius: .16rem;
            padding: 0 .3rem;
            overflow: hidden;
            .notice_top{
                line-height: 1.8;
                padding: .25rem 0;
                .notice_tit{
                    line-height: 1.8;
                    padding-right: .3rem;
                    span{
                        font-weight: normal;
                    }
                }
            }
            .notice_bot{
                line-height: .54rem;
                padding: .2rem 0;
                border-top: .02rem dashed #E9E9E9;
                .confirm{
                    width: 1.6rem;
                    height: .6rem;
                    font-size: .26rem;
                    color: #4E9DF6;
                    line-height: .22rem;
                    border-radius: .4rem;
                    border: 0.02rem solid #4E9DF6;
                    margin-left: auto;
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }
}
</style>