<template>
    <div class="container">        
        <navbar title="中国气象科技园区物业运维公众号" :leftArrow="false" bgColor="#F7F7F7"></navbar>
        <div class="property">
            <div class="wrap">
                <div class="property_box">
                    <h3 class="property_h3 font-26 color-9">今日接单量为<strong>357</strong>次</h3>
                    <ul class="order_list flex flex-wrap font-24 color-6 text-center">
                        <li>
                            <h4 class="font-24 color-m"><strong class="font-48">5989</strong>次</h4>
                            <p>房屋维修</p>
                        </li>
                        <li>
                            <h4 class="font-24 color-m"><strong class="font-48">237</strong>次</h4>
                            <p>电器维修</p>
                        </li>
                        <li>
                            <h4 class="font-24 color-m"><strong class="font-48">16</strong>次</h4>
                            <p>安装工单</p>
                        </li>
                        <li>
                            <h4 class="font-24 color-m"><strong class="font-48">0</strong>次</h4>
                            <p>其他服务</p>
                        </li>
                    </ul>
                </div>
                <div class="property_box property_box2" @click="toNews">
                    <h2 class="property_h2 font-30 color-3">消息中心</h2>
                    <div class="property_text font-24 color-6">
                        <p>工单信息实时更新!</p>
                    </div>
                    <div class="toSen font-24 color-f">去看看</div>
                </div>
                <ul class="property_bot flex justify-between text-center">
                    <li>
                        <router-link to="/property_order?status=1" class="home_top_link">
                        <div class="property_box flex flex-col justify-center align-center">
                            <div class="property_icon">
                                <img src="@/assets/icon/property_icon_01.png" alt="待处理工单">
                            </div>
                            <h4 class="property_h4 font-24 color-3">待处理工单</h4>
                        </div>
                        </router-link>
                    </li>
                    <li>
                        <div class="property_box flex flex-col justify-center align-center">
                            <div class="property_icon">
                                <img src="@/assets/icon/property_icon_01.png" alt="待处理工单">
                            </div>
                            <h4 class="property_h4 font-24 color-3">历史工单</h4>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <TabbarProperty :current="0"></TabbarProperty>
    </div>
</template>
<script>
import TabbarProperty from '@/components/TabbarProperty.vue'
export default {
    name: 'property',
    components: { TabbarProperty },
    data () {
        return {
            userInfo: {}
        }
    },
    methods: {
        toNews(){
            this.$router.push({path:'/property_news',query: {user:'123456'}})
        },
        getInfo() {
            this.$store.dispatch('GetInfo').then((res) => {
                console.log(res)
                this.userInfo = res
            })
        },
    },
    created () {
        this.getInfo()
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.property_box{
    padding: .3rem;
    background: #FFFFFF;
    box-shadow: 0px .04rem .2rem rgba(0,0,0,0.05);
    border-radius: .16rem;
    margin-bottom: .3rem;
}
.property_box2{
    height: 3.72rem;
    background-image: url('~@/assets/bg/property_bg2.png');
    background-repeat: no-repeat;
    background-size: 4.44rem auto;
    background-position: right bottom;
}
.property_h2{
    line-height: 1.6;
    margin: .1rem 0;
}
.property_h3{
    line-height: 1.8;
    margin: .1rem 0;
    font-weight: normal;
}
.property_h4{
    line-height: 2;
    font-weight: normal;
}
.order_list{
    list-style: none;
    overflow: hidden;
    li{
        width: 50%;
        padding: .3rem;
        border: 1px solid #F5F5F5;
        border-right: none;
        border-bottom: none;
        margin-top: -1px;
        margin-left: -1px;
        h4{
            line-height: .5rem;
            strong{
                vertical-align: baseline;
                margin-right: .04rem;
            }
        }
    }
}
.property_text{
    margin: .1rem 0;
}
.toSen{
    width: 1.12rem;
    line-height: .44rem;
    background: #E89D00;
    border-radius: .22rem;
    text-align: center;
    margin-top: .5rem;
}
.property_bot{
    list-style: none;
    margin: 0 -.15rem;
    li{
        width: 50%;
        padding: 0 .15rem;
    }
    .property_box{
        min-height: 3.3rem;
    }
}
.property_icon{
    width: .74rem;
    height: .9rem;
    margin: 0 auto .1rem;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
</style>