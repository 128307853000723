<template>
    <div class="container">
        <navbar title="意见反馈" bgColor="#F7F7F7"></navbar>
        <div class="tab_bg tab_bg2">
            <div class="wrap">
                <van-tabs 
                    v-model="active"
                    color="#4E9DF6"
                    background="transparent"
                    line-width=".56rem"
                    line-height=".06rem"
                    title-active-color="#4E9DF6"
                    title-inactive-color="#666666"
                    :ellipsis="false"
                    class="tab_list"
                    @click="tabChange"
                >
                    <van-tab title="功能建议" name="1"></van-tab>
                    <van-tab title="体验优化" name="2"></van-tab>
                    <van-tab title="其他建议" name="3"></van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="feedback_box">
            <div class="wrap">
                <van-form @submit="onSubmit" class="feedback_form">
                    <div class="feedback">
                        <van-field
                            v-model="content"
                            name="content"
                            placeholder="请输入您的建议"
                            type="textarea"
                            rows="4"
                            autosize
                            :rules="[{ required: true, message: '请输入您的建议' }]"
                        />
                    </div>
                    <div class="feedback_tips font-24 color-6">您的每一条反馈我们都会认真对待，确保给您最满意的服务！</div>
                    <div class="bot_button">
                        <van-button class="custom_btn" round block native-type="submit">确认提交</van-button>
                    </div>
                </van-form>
            </div>          
        </div>
    </div>
</template>
<script>
export default {
    name: 'feedback',
    data () {
        return {
            active: 0,
            content: ''
        }
    },
    methods: {
        // 建议切换
        tabChange(name) {
            this.$toast(name)
        },
        // 提交
        onSubmit(values) {
            console.log(this.active)
            console.log('submit', values);
        },
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.feedback_box{
    padding-bottom: 1.8rem;
    padding-bottom: calc(1.8rem + constant(safe-area-inset-bottom));
    padding-bottom: calc(1.8rem + env(safe-area-inset-bottom));
}
.feedback{
    padding: .4rem .2rem;
    background: #FFFFFF;
    border-radius: .16rem;
    margin-bottom: .2rem;
    .van-cell{
        font-size: .24rem;
        line-height: 1.6;
        padding: .2rem;
        border-radius: .08rem;
        border: .02rem solid #EEEEEE;
    }
    ::v-deep .van-field__control{
        color: #333333;
    }
    ::v-deep .van-field__control:disabled{
        color: #999999;
        -webkit-text-fill-color: #999999;
    }
    ::v-deep .van-field__error-message{
        font-size: .22rem;
    }
}
.feedback_tips{
    margin: .2rem 0;
}

</style>