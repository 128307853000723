<template>
    <div class="container">        
        <navbar title="账单详情" bgColor="#F7F7F7"></navbar>
        <div class="tab_bg">
            <div class="wrap">
                <van-tabs 
                    v-model="active"
                    color="#4E9DF6"
                    background="transparent"
                    line-width=".56rem"
                    line-height=".06rem"
                    title-active-color="#4E9DF6"
                    title-inactive-color="#666666"
                    :ellipsis="false"
                    class="tab_list"
                    @click="tabChange"
                >
                    <van-tab title="电费" name="1"></van-tab>
                    <van-tab title="水费" name="2"></van-tab>
                    <van-tab title="供暖费" name="3"></van-tab>
                    <van-tab title="物业管理费" name="4"></van-tab>
                    <van-tab title="物管费押金" name="5"></van-tab>
                    <van-tab title="车位管理费" name="6"></van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="detail">
            <div class="wrap">
                <div class="repair_form">
                    <van-row type="flex">
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="time"
                                label="所属期间:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="prevMouth"
                                label="上月读数:"
                                name="prevMouth"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="currentMouth"
                                label="本月度数:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="price"
                                label="单价:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="payable"
                                label="本月应交:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="underpayment"
                                label="上月欠交:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="total"
                                label="应交小计:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                    </van-row>
                </div>
            </div>
        </div>
        <div class="bot_bg">
            <div class="wrap">
                <div class="bot flex justify-between align-center">
                    <span class="font-28 color-6">应付</span>
                    <strong class="font-36 color-m">¥3218.56</strong>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'bill',
    data () {
        return {
            active: 0,
            time:'2023-2-17',
            prevMouth:'12317',
            currentMouth: '112739',
            price: '12.98',
            payable: '12.98',
            underpayment: '12.98',
            total: '12.98'            
        }
    },
    methods: {
        // 切换
        tabChange(name) {
            this.$toast(name)
        }
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.detail{    
    padding-bottom: 1.6rem;
    padding-bottom: calc(1.6rem + constant(safe-area-inset-bottom));
    padding-bottom: calc(1.6rem + env(safe-area-inset-bottom));
}
.repair_form{
    padding: .3rem .2rem;
    border-radius: .1rem;
    margin-bottom: .3rem;
    background-color: #FFFFFF;
}
.repair_name{
    line-height: 1.4;
    padding-bottom: .2rem;
    font-weight: normal;
}
.repair_input{
    display: block;
    font-size: .28rem;
    color: #666666;
    line-height: .8rem;
    padding: 0;
    margin-bottom: .4rem;
    &:active{
        background-color: transparent;
    }
    ::v-deep .van-field__label{
        width: 100%;
        line-height: 2;
        margin: .1rem 0;
        color: #666666;
    }
    ::v-deep .van-field__body{
        width: 100%;
        height: .8rem;
        border-radius: .08rem;
        border: .02rem solid #EEEEEE;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .76rem;
        padding: 0 .2rem;
        border-radius: .08rem;
    }
    ::v-deep .van-field__control:disabled{
        color: #999999;
        -webkit-text-fill-color: #999999;
    }
    ::v-deep .van-field__button{
        padding-right: .1rem;
    }
    ::v-deep .van-field__button img{
        width: .32rem;
        height: .32rem;
        object-fit: contain;
        vertical-align: middle;
    }
    ::v-deep .van-field__error-message{
        font-size: .2rem;
        line-height: 1.4;
        margin-top: .1rem;
        padding: 0 .2rem;
    }
}
.bot_bg{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 750px;
    margin: 0 auto;
    background-color: #FFFFFF;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    border-radius: .1rem .1rem 0px 0px;
    box-shadow: 0 0 .1rem rgba($color: #000000, $alpha: 0.1);
    .bot{
        line-height: .6rem;
        padding: .2rem 0 .4rem;
    }
}
</style>