<template>
    <div class="container">
        <navbar title="基本信息" bgColor="#F7F7F7"></navbar>
        <div class="wrap">
            <div class="info_bg">
                <van-uploader class="avatar">
                    <img src="@/assets/upload/avatar2.png" alt="默认头像">
                </van-uploader>
            </div>
            <van-form @submit="onSubmit" class="info_list">
                <van-field
                    v-model="info.name"
                    label="文本"
                    name="name"
                    placeholder="请输入您的姓名"
                    input-align="right"
                    :rules="[{ required: true, message: '请输入您的姓名' }]"
                />
                <van-field
                    v-model="info.account"
                    label="账号"
                    name="account"
                    placeholder="请输入您的账号"
                    input-align="right"
                    :rules="[{ required: true, message: '请输入您的账号' }]"
                />
                <van-field
                    v-model="info.sex"
                    readonly
                    clickable
                    label="性别 "
                    name="sex"
                    placeholder="请选择您的性别"
                    input-align="right"
                    :rules="[{ required: true, message: '请选择您的性别' }]"
                    @click="showPicker = true"
                />
                <van-popup v-model="showPicker" round position="bottom">
                    <van-picker
                        show-toolbar
                        title="请选择您的性别"
                        :columns="columns"
                        :default-index="0"
                        @cancel="showPicker = false"
                        @confirm="onConfirm"
                    />
                </van-popup>
                <van-field
                    v-model="info.address"
                    label="我的地址 "
                    name="address"
                    placeholder="请输入您的地址"
                    input-align="right"
                    :rules="[{ required: true, message: '请输入您的地址' }]"
                />
                <van-field
                    label="登陆密码"
                    name="address"
                    placeholder=""
                    input-align="right"
                    readonly
                    right-icon="arrow"
                />
                <van-field
                    v-model="info.id"
                    label="证件信息 "
                    name="address"
                    placeholder="请输入您的证件信息"
                    input-align="right"
                    :rules="[{ required: true, message: '请输入您的证件信息' }]"
                />
                <van-field
                    v-model="info.qualification"
                    label="资质信息 "
                    name="address"
                    placeholder="请输入您的资质信息"
                    input-align="right"
                    :rules="[{ required: true, message: '请输入您的资质信息' }]"
                />
                <div class="bot_button">
                    <van-button class="custom_btn" round block native-type="submit">保存</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>
<script>
export default {
    name: 'information',
    data () {
        return {
            info:{
                name: '张伟',
                account: '13269251878',
                sex: '男',
                address: '北京市丰台区建设路40号',
                id: '410521198906170927',
                qualification: '43985378975'

            },
            showPicker: false,
            columns: ['男', '女'],
        }
    },
    methods: {
        onConfirm(value) {
            this.info.sex = value;
            this.showPicker = false;
        },
        onSubmit(values) {
            console.log('submit', values);
        },
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    .info_bg{
        background-color: #FFFFFF;
        overflow: hidden;
        .avatar{
            display: block;
            width: 1.76rem;
            height: 1.76rem;
            border-radius: 50%;
            margin: .6rem auto;
        }
    }
    .info_list{
        padding-bottom: 1.8rem;
        padding-bottom: calc(1.8rem + constant(safe-area-inset-bottom));
        padding-bottom: calc(1.8rem + env(safe-area-inset-bottom));
        .van-cell{
            font-size: .28rem;
            line-height: 2.4;
            padding: .2rem .3rem;
            &::after{
                left: .3rem;
                right: .3rem;
                border-bottom: .02rem dashed #E9E9E9;
            }
            ::v-deep .van-field__label{
                color: #333333;
                
            }
            ::v-deep .van-field__control{
                color: #666666;
            }
            ::v-deep .van-field__right-icon{
                .van-icon{
                    font-size: .28rem;
                    color: #CCCCCC;
                }
            }
            ::v-deep .van-field__error-message{
                font-size: .22rem;
                line-height: 1.4;
                text-align: right;
            }
        }
    }
</style>