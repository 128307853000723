import { Toast } from 'vant'

function formatTime(duration){
  return [duration/60, duration%60].map(n=>String(parseInt(n)).padStart(2,'0')).join(':')
}

export const player = {
  namespaced: true,
  state: ()=>({
    currentMusic: null,
    audio: document.createElement('audio'),
    loading: false,
    playing: false,
    currentTime: 0,
    duration: 0,
  }),
  getters: {
    getMusicStatus(state){
      return (music)=>{
        if(!state.currentMusic || state.currentMusic.id!==music.id) return 'idle'
        return state.loading ? 'loading' : state.playing ? 'playing' : 'paused'
      }
    },
    progress(state){
      return state.currentTime / state.duration
    },
    currentTimeFormatted(state){
      return formatTime(state.currentTime)
    },
    durationFormatted(state){
      return formatTime(state.duration)
    }
  },
  mutations: {
    load(state, music){
      const { audio } = state
      if(!state.currentMusic || state.currentMusic.id !== music.id){
        state.currentMusic = music
        audio.src = music.audio
        audio.load()
      }
    },
    play(state, music){
      const { audio } = state
      if(music) this.commit('player/load', music)
      audio.play().catch(e=>Toast.fail(e.toString()))
    },
    pause(state){
      state.audio.pause()
    },
    stop(state){
      state.currentMusic = null
      state.audio.pause()
      state.audio.currentTime = 0
    },
    setProgress(state, progress){
      state.audio.currentTime = state.duration * progress
    },
    updateState(state, payload){
      Object.assign(state, payload)
    }
  },
  actions: {
    mount({ commit, state }, parentEl){
      const { audio, currentMusic } = state

      function updateState(payload){
        commit('updateState', payload)
      }

      if(!audio.parentElement){
        audio.addEventListener('ended', ()=>{
          updateState({
            currentMusic: null
          })
        })
        audio.addEventListener('waiting', ()=>{
          updateState({
            loading: true
          })
        })
        audio.addEventListener('playing', ()=>{
          updateState({
            loading: false,
          })
        })
        audio.addEventListener('play', ()=>{
          updateState({
            playing: true
          })
        })
        audio.addEventListener('pause', ()=>{
          updateState({
            playing: false
          })
        })
        audio.addEventListener('durationchange', ()=>{
          updateState({
            duration: audio.duration
          })
        })
        audio.addEventListener('timeupdate', ()=>{
          updateState({
            currentTime: audio.currentTime
          })
        })
  
        audio.setAttribute('style', 'position:absolute; width:0; height:0;')
      }

      if(currentMusic){
        audio.src = currentMusic.audio
        if(state.playing){
          audio.load()
          audio.play().catch(e=>Toast.fail(e.toString()))
        }else{
          audio.pause()
        }
      }

      parentEl.append(audio)
    }
  },
}