import request, { get } from '@/utils/request'

export default {

    theoty(params = {}) {
        return request('/theoty/index', {params})
    },
    // banner
    banner(params = {}) {
        return get('/banner', {params})
    },
    // 首页接口
    index(params = {}) {
        return get('/index/index', {params})
    },
    //公告
    notice(params = {}) {
        return get('/notice', {params})
    },
}
