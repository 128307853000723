<template>
    <div class="container">
        <navbar title="进出门申请" bgColor="#F7F7F7"></navbar>
        <div class="detail">
            <div class="wrap">
                <div class="repair_form">
                    <van-row type="flex">
                        <van-col span="24">
                            <h3 class="repair_name font-28 color-6">申请表类型</h3>
                            <van-radio-group v-model="isCome" direction="horizontal" class="custom_radio" disabled>
                                <van-radio name="1">
                                    进门申请
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? radioIcon : radioIcon2" />
                                    </template>
                                </van-radio>
                                <van-radio name="2">
                                    出门申请
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? radioIcon : radioIcon2" />
                                    </template>
                                </van-radio>
                            </van-radio-group>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="apply_time"
                                label="申请时间:"
                                name="apply_time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="name"
                                label="客户名称:"
                                name="name"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="address"
                                label="承租区域:"
                                name="address"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="contacts"
                                label="联系人"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="phone"
                                label="电话:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                rows="3"
                                autosize
                                type="textarea"
                                v-model="detailInfo"
                                label="进/出物品详细数量日期及时间:"
                                name="content"
                                class="repair_input repair_textarea"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="car_num"
                                label="车辆数量:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="car_type"
                                label="汽车型号:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="car_id"
                                label="车牌号:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="carry_company"
                                label="搬运公司名称:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="carry_num"
                                label="搬运工人数量:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="carry_name"
                                label="现场负责人:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="carry_phone"
                                label="现场负责人联系电话:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="carry_lift"
                                label="使用电梯编号:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                rows="3"
                                autosize
                                type="textarea"
                                v-model="carry_route"
                                label="搬运路线:"
                                name="content"
                                class="repair_input repair_textarea"
                            />
                        </van-col>
                        <van-col span="24">
                            <div class="photo_upload flex">
                                <span class="photo_left font-26 color-6">上传照片：</span>
                                <div class="photo_right">
                                    <van-uploader 
                                        v-model="photo_list"
                                        multiple
                                        :deletable = 'false'
                                        :show-upload = 'false'
                                    />
                                </div>
                            </div>
                        </van-col>
                    </van-row>
                </div>
                <div class="status_box">
                    <h3 class="font-28 color-3">申请状态</h3>
                    <p class="font-28 color-6">申请状态</p>
                    <van-field
                        readonly
                        v-model="status"
                        name="status"
                        :class="['repair_input', status == '未通过' ? 'pending' : (status == '处理中' || '已通过') ? 'processing' : '']"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'reservation_detail',
    data () {
        return {
            isCome: '1',
            radioIcon: require('@/assets/icon/check_blue.png'),
            radioIcon2: require('@/assets/icon/check_gray.png'),
            apply_time: '2023-2-23',
            name: '王志刚',
            address: '5号院3号楼15楼层1503号',
            contacts: '王志刚',
            phone: '13682987635',
            detailInfo: '2023.2.23搬家具申请进门条，家具包括家电5件…',
            car_num: '2',
            car_type: '小型',
            car_id: '京A45397953',
            carry_company: '大兵搬家',
            carry_num: '10',
            carry_name: '王伟',
            carry_phone: '17398729817',
            carry_lift: '001',
            carry_route: '园区主干道',
            photo_list: [
                { url: require('@/assets/upload/photo.png') }
            ],
            status: '已通过'

        }
    },
    methods: {
        onConfirmArea(values) {
            this.area = values
            this.showPickerArea = false;
        },
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.detail{    
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
}
.repair_form{
    padding: .3rem .2rem;
    border-radius: .1rem;
    margin-bottom: .3rem;
    background-color: #FFFFFF;
}
.repair_name{
    line-height: 1.4;
    padding-bottom: .2rem;
    font-weight: normal;
}
.repair_input{
    display: block;
    font-size: .24rem;
    color: #666666;
    line-height: .8rem;
    padding: 0;
    margin-bottom: .4rem;
    &:active{
        background-color: transparent;
    }
    ::v-deep .van-field__label{
        width: 100%;
        line-height: 2;
        margin: .1rem 0;
        color: #666666;
    }
    ::v-deep .van-field__body{
        width: 100%;
        height: .8rem;
        border-radius: .08rem;
        border: .02rem solid #EEEEEE;
        background: #FFFFFF; 
    }
    ::v-deep .van-field__control{
        line-height: .76rem;
        padding: 0 .2rem;
        border-radius: .08rem;
    }
    
    ::v-deep .van-field__control:disabled{
        color: #999999;
        -webkit-text-fill-color: #999999;
    }
    ::v-deep .van-field__button{
        padding-right: .1rem; 
    }
    ::v-deep .van-field__button img{
        width: .32rem;
        height: .32rem;
        object-fit: contain;
        vertical-align: middle;
    }
    ::v-deep .van-field__error-message{
        font-size: .2rem;
        line-height: 1.4;
        margin-top: .1rem;
        padding: 0 .2rem;
    }
}
.repair_textarea{
    ::v-deep .van-field__body{
        width: 100%;
        height: auto;
        border-radius: .08rem;
        border: .02rem solid #DFDFDF;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .4rem;
        padding: .2rem;
        border-radius: .08rem;
    }
}
.pending ::v-deep .van-field__control{
    color: #00B0F0;
}
.processing ::v-deep .van-field__control{
    color: #39DCAB;
}
.evaluated ::v-deep .van-field__control{
    color: #F8B142;
}
.photo_upload{
    margin-bottom: .4rem;
    .photo_left{
        display: block;
        line-height: 2;
        margin-bottom: .15rem;
    }
    .photo_right{
        ::v-deep .van-uploader{
            margin-bottom: -.46rem;
        }
        ::v-deep .van-uploader__preview{
            margin-right: .46rem;
            margin-bottom: .46rem;
        }
        ::v-deep .van-uploader__preview-image{
            width: 1.6rem;
            height: 1.6rem;
        }
        ::v-deep .van-uploader__preview-delete{
            top: -.16rem;
            right: -.16rem;
            width: .32rem;
            height: .32rem;
            border-radius: 50%;
            background-color: #4E9DF6;
        }
        ::v-deep .van-uploader__preview-delete-icon{
            font-size: .32rem;
            top: 0;
            right: 0;
            transform: scale(0.6);
        }
        ::v-deep .van-uploader__upload{
            width: 1.6rem;
            height: 1.6rem;
            margin-right: .46rem;
            margin-bottom: .46rem;
        }
        ::v-deep .van-uploader__upload-icon{
            font-size: .64rem;
        }
    }
    .photo_default{
        width: 1.6rem;
        height: 1.6rem;
        border-radius: .08rem;
        border: .02rem solid #D4D5D6;
        img{
            width: .56rem;
            height: .56rem;
            object-fit: contain;
            margin: 0 auto .1rem;
        }
    }
} 

.status_box{
    line-height: 2;
    padding: .3rem .2rem;
    margin-bottom: .3rem;
    border-radius: .1rem;
    background-color: #FFFFFF;
    .repair_input{
        margin-bottom: 0;
    }
}
.star{
    margin: .1rem 0 .25rem;
    ::v-deep .van-rate__item{
        margin-right: .4rem;
    }
    ::v-deep .van-rate__icon{
        width: .48rem;
        height: .48rem;
        font-size: 0;
    }
    ::v-deep .van-icon__image{
        width: 100%;
        height: 100%;
    }
}
.bot_height{
    height: 1.5rem;
    height: calc(1.5rem + constant(safe-area-inset-bottom));
    height: calc(1.5rem + env(safe-area-inset-bottom));
}
</style>