<template>
    <div class="container">
        <navbar title="我的访客" bgColor="#F7F7F7"></navbar>
        <div class="tab_bg tab_bg2">
            <div class="wrap">                
                <van-tabs 
                    v-model="active"
                    color="#4E9DF6"
                    background="transparent"
                    line-width=".56rem"
                    line-height=".06rem"
                    title-active-color="#4E9DF6"
                    title-inactive-color="#666666"
                    :ellipsis="false"
                    class="tab_list"
                    @click="tabChange"
                >
                    <van-tab title="全部" name="1"></van-tab>
                    <van-tab title="已确认" name="2"></van-tab>
                    <van-tab title="未确认" name="3"></van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="visitors">
            <div class="wrap">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    class="visitors_list"
                >
                    <div class="visitors_item font-24 color-6">
                        <div class="visitors_top flex justify-between align-center">
                            <p class="visitors_time">到访时间：2022年01月01日</p>
                            <p class="status">已确认</p>
                        </div>
                        <div class="visitors_bot flex align-center">
                            <p class="name">张先生</p>
                            <p class="tel">13269251878</p>
                            <!-- <van-button class="confirm" plain round block type="info">确认</van-button> -->
                        </div>
                    </div>
                    <div class="visitors_item font-24 color-6">
                        <div class="visitors_top flex justify-between align-center">
                            <p class="visitors_time">到访时间：2022年01月01日</p>
                            <p class="status no">未确认</p>
                        </div>
                        <div class="visitors_bot flex align-center">
                            <p class="name">张先生</p>
                            <p class="tel">13269251878</p>
                            <van-button class="confirm" plain round block type="info">确认</van-button>
                        </div>
                    </div>
                    <div class="visitors_item font-24 color-6">
                        <div class="visitors_top flex justify-between align-center">
                            <p class="visitors_time">到访时间：2022年01月01日</p>
                            <p class="status">已确认</p>
                        </div>
                        <div class="visitors_bot flex align-center">
                            <p class="name">张先生</p>
                            <p class="tel">13269251878</p>
                            <!-- <van-button class="confirm" plain round block type="info">确认</van-button> -->
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'visitors',
    data () {
        return {
            active: 0,
            list: [],
            loading: false,
            finished: false,
        }
    },
    methods: {
        // 类型切换
        tabChange(name) {
            this.$toast(name)
        },
        onLoad() {
            
        }
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    .visitors{
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }
    .visitors_item{
        padding: 0 .3rem;
        border-radius: .16rem;
        background-color: #FFFFFF;
        margin-bottom: .3rem;
        .visitors_top{
            line-height: 2;
            padding: .18rem 0;
            .visitors_time{
                color: #999999;
            }
            .status{
                color: #4E9DF6;
                &.no{
                    color: #FE7A7A;
                }
            }
        }
        .visitors_bot{
            padding: .14rem 0;
            border-top: .02rem solid #E9E9E9;
            .name{
                flex-shrink: 0;
                min-width: 1.5rem;
                color: #000000;
                font-size: .28rem;
                font-weight: bold;
                margin-right: .2rem;
            }
            .tel{
                padding-left: .38rem;
                background: url('~@/assets/icon/tel.png') left center no-repeat;
                background-size: .28rem;
            }
            .confirm{
                width: 1.6rem;
                height: .6rem;
                font-size: .26rem;
                color: #4E9DF6;
                line-height: .22rem;
                border-radius: .4rem;
                border: 0.02rem solid #4E9DF6;
                margin-left: auto;
                &::before{
                    display: none;
                }
            }
        }
    }
</style>