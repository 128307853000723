<template>
    <div class="container">
        <navbar title="我的收据" bgColor="#F7F7F7"></navbar>
        <div class="receipt">
            <div class="wrap">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    class="receipt_list"
                >
                    <router-link to="/receipt_detail" class="receipt_item font-28 color-6">
                        <div class="receipt_top flex justify-between align-center">
                            <p class="date">2023年1月份账单</p>
                            <p class="price font-36 color-m">¥3218.56</p>
                        </div>
                        <p class="receipt_time font-24 color-9">缴费时间：2021-10-10</p>
                    </router-link>
                    <router-link to="/receipt_detail" class="receipt_item font-28 color-6">
                        <div class="receipt_top flex justify-between align-center">
                            <p class="date">2023年1月份账单</p>
                            <p class="price font-36 color-m">¥3218.56</p>
                        </div>
                        <p class="receipt_time font-24 color-9">缴费时间：2021-10-10</p>
                    </router-link>
                    <router-link to="/receipt_detail" class="receipt_item font-28 color-6">
                        <div class="receipt_top flex justify-between align-center">
                            <p class="date">2023年1月份账单</p>
                            <p class="price font-36 color-m">¥3218.56</p>
                        </div>
                        <p class="receipt_time font-24 color-9">缴费时间：2021-10-10</p>
                    </router-link>
                </van-list>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'receipt',
    data () {
        return {
        }
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    .receipt{
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }
    .receipt_item{
        display: block;
        line-height: 1.5;
        padding: .3rem;
        margin-bottom: .3rem;
        background-color: #FFFFFF;
        border-radius: .16rem;
        .receipt_top{
            .price{
                font-weight: bold;
            }
        }
        .receipt_time{
            font-size: .24rem;
            color: #999999;
        }
    }
</style>