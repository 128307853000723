<template>
    <div class="list_box container">
        <navbar title="公告" bgColor="#F7F7F7"></navbar>
        <div class="tab_bg">
            <div class="wrap">                
                <van-tabs 
                    v-model="active"
                    color="#4E9DF6"
                    background="transparent"
                    line-width=".56rem"
                    line-height=".06rem"
                    title-active-color="#4E9DF6"
                    title-inactive-color="#666666"
                    :ellipsis="false"
                    class="tab_list"
                    @click="tabChange"
                >
                    <van-tab title="物业公告" name="1"></van-tab>
                    <van-tab title="安全提醒" name="2"></van-tab>
                    <van-tab title="紧急提醒" name="3"></van-tab>
                    <van-tab title="其他消息" name="4"></van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="list">
            <div class="wrap">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    class="notice_list"
                >
                    <!-- 已阅 添加class=read -->
                    <div class="notice_item read">
                        <router-link class="notice_link font-24 color-6" to="/notice_detail">
                            <div class="notice_top">
                                <h3 class="notice_tit font-28 color-0 ellipsis">温馨提示，您的物业费已到期请您续交！</h3>
                                <p class="ellipsis">您的订单39247270972当前已发货，请您及时查收。</p>
                            </div>
                            <div class="notice_bot">
                                <span class="notice_time">时间：2021-10-10 10:00:00</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="notice_item">
                        <router-link class="notice_link font-24 color-6" to="/notice_detail">
                            <div class="notice_top">
                                <h3 class="notice_tit font-28 color-0 ellipsis">紧急提醒，您的水费已到期请您续交！</h3>
                                <p class="ellipsis">您的订单39247270972当前已发货，请您及时查收。</p>
                            </div>
                            <div class="notice_bot">
                                <span class="notice_time">时间：2021-10-10 10:00:00</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="notice_item">
                        <router-link class="notice_link font-24 color-6" to="/notice_detail">
                            <div class="notice_top">
                                <h3 class="notice_tit font-28 color-0 ellipsis">最新消息，您的订单已发货！</h3>
                                <p class="ellipsis">您的订单39247270972当前已发货，请您及时查收。</p>
                            </div>
                            <div class="notice_bot">
                                <span class="notice_time">时间：2021-10-10 10:00:00</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="notice_item">
                        <router-link class="notice_link font-24 color-6" to="/notice_detail">
                            <div class="notice_top">
                                <h3 class="notice_tit font-28 color-0 ellipsis">最新消息，您的订单已发货！</h3>
                                <p class="ellipsis">您的订单39247270972当前已发货，请您及时查收。</p>
                            </div>
                            <div class="notice_bot">
                                <span class="notice_time">时间：2021-10-10 10:00:00</span>
                            </div>
                        </router-link>
                    </div>
                </van-list>
            </div>
        </div>
    </div>
</template>

<script>
export default {    
    name: 'NoticeList',    
    data() {
        return {
            active: 2,
            list: [],
            loading: false,
            finished: false,
        }
    },
    created() {
       
    },
    methods: {
        // 公告切换
        tabChange(name) {
            this.$toast(name)
        },
        onLoad() {
            
        }
    }
}
</script>
<style lang="scss" scoped>
.tab_bg{
    position: sticky;
    top: .9rem;
    background-color: #F7F7F7;
    z-index: 10;
    .tab_list{
        margin-bottom: .3rem;
        ::v-deep .van-tabs__nav{
            justify-content: space-between;
            margin: 0 -.1rem;
            padding: 0;
            &.van-tabs__nav--line{
                padding-bottom: .1rem;
            }
            .van-tab{
                flex: none;
                line-height: 1.8;
                padding: 0 .1rem;
                .van-tab__text{
                    font-size: .28rem;
                }
            }
            .van-tabs__line{
                bottom: 0;
            }
        }
        ::v-deep .van-tabs__wrap{
            height: auto;
        }
    }
}
.list{
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}
.notice_list{
    list-style: none;
    overflow: hidden;
    .notice_item{
        position: relative;
        margin-bottom: .3rem;
        &.read::before{
            display: block;
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: .9rem;
            height: .9rem;
            background: url('~@/assets/icon/read.png') right top no-repeat;
            background-size: cover;
        }
        .notice_link{
            display: block;
            background: #FFFFFF;
            border-radius: .16rem;
            padding: 0 .3rem;
            overflow: hidden;
            .notice_top{
                line-height: 1.8;
                padding: .25rem 0;
                .notice_tit{
                    line-height: 1.8;
                }
            }
            .notice_bot{
                line-height: .54rem;
                padding: .2rem 0;
                border-top: .02rem dashed #E9E9E9;
            }
        }
    }
}
</style>