import { get } from '@/utils/request'

export default {
    // 公告列表
    getList(params) {
        return get('/notice/list', { params })
    },
    // 公告详情
    getDetail(params) {
        return get('/notice/detail', { params })
    },

}
