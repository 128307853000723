<template>
    <div class="detail_box container">
        <navbar title="招商详情"></navbar>
        <div class="banner">
            <img :src="info.pic" alt="">
        </div>
        <div class="detail_info">
            <div class="detail_info_top">
                <div class="wrap">
                    <h1 class="detail_name font-30 color-3">{{info.name}}</h1>
                    <div class="detail_time flex justify-between align-center font-24 color-9">
                        <span>招商时间：{{info.investment_time}}</span>
                        <span>热线电话：<strong>{{info.mobile}}</strong></span>
                    </div>
                </div>
            </div>
            <ul class="detail_info_bot font-28 color-3">
                <li>
                    <span>状态</span>
                    <p class="org">{{info.status}}</p>
                    <div class="clear"></div>
                </li>
                <li>
                    <span>地址</span>
                    <p>{{info.address}}}</p>
                    <div class="clear"></div>
                </li>
                <li>
                    <span>价格</span>
                    <p>{{info.price}}</p>
                    <div class="clear"></div>
                </li>
            </ul>
        </div>
        <div class="detail_text">
            <div class="wrap">
                <h2 class="detail_tit font-28 color-9 text-center"><span>招商描述</span></h2>
                <div class="detail_con font-26 color-6">
                    <text>{{info.content}}</text>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import merchant from '@/api/merchant'
export default {
    name: 'MerchantsDdetail',
    data () {
        return {
            id: '',
            info: {}
        }
    },
    created() {
        this.id = this.$route.query.id
        this.getDetail()
    },
    methods: {
        getDetail() {
            merchant.getDetail({id: this.id}).then((res) => {
                if (res.status == 0) {
                    this.info = res.data
                }
            })
        }
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.banner{
    height: 6.6rem;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.detail_info{
    .detail_info_top{
        margin-bottom: .3rem;
        background-color: #FFFFFF;
        overflow: hidden;
        .detail_name{
            line-height: 1.6;
            margin: .2rem 0 .1rem;
        }
        .detail_time{
            line-height: 1.8;
            margin: .1rem 0;
            strong{
                color: #4E9DF6;
            }
        }
    }
    .detail_info_bot{
        li{
            line-height: .5rem;
            padding: .25rem .3rem;
            margin-bottom: .3rem;
            background-color: #FFFFFF;
            overflow: hidden;
            span{
                float: left;
                color: #999999;
                margin-right: .2rem;
            }
            p{
                text-align: right;
                overflow: hidden;
                &.org{
                    color: #F8B142;
                }
            }
        }
    }
}
.detail_text{
    background-color: #FFFFFF;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
    .detail_tit{
        line-height: .7rem;
        margin: .2rem 0;
        font-weight: normal;
        overflow: hidden;
        span{
            position: relative;
            display: inline-block;
            vertical-align: middle;
            padding: 0 .3rem;
            &::before,
            &::after{
                position: absolute;
                top: 50%;
                margin-top: -1px;
                display: block;
                content: "";
                width: 2rem;
                height: .02rem;
            }
            &::before{
                right: 100%;
                background: linear-gradient(90deg, #F2F5F7 0%, #BDC2C5 100%);
            }
            &::after{
                left: 100%;
                background: linear-gradient(243deg, #F2F5F7 0%, #BDC2C5 100%);

            }
        }
    }
    .detail_con{
        line-height: 1.8;
        margin: .2rem 0 .6rem;
    }
}
</style>
