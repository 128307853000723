<template>
    <div class="container">
        <navbar title="预约详情" bgColor="#F7F7F7"></navbar>
        <div class="detail">
            <div class="wrap">
                <div class="repair_form">
                    <van-row type="flex">
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="service_name"
                                label="服务名称:"
                                name="service_name"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="service_name"
                                label="服务时间:"
                                name="service_name"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="repair_name"
                                label="报修名称:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="name"
                                label="姓名:"
                                name="name"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="phone"
                                label="phone:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>                         
                        <van-col span="11">
                            <van-field
                                readonly
                                clickable
                                v-model="area"
                                label="区域:"
                                name="area"
                                class="repair_input"
                                @click="showPickerArea = true"                            >                                
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11" offset="2">
                            <van-field
                                readonly
                                clickable
                                v-model="building"
                                label="楼号:"
                                name="building"
                                class="repair_input"
                                @click="showPickerArea = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11">
                            <van-field
                                readonly
                                clickable
                                v-model="floor"
                                label="楼层:"
                                name="floor"
                                :rules="[{ required: true, message: '请选择楼层：' }]"
                                class="repair_input"
                                @click="showPickerArea = true"
                            >                                
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11" offset="2">
                            <van-field
                                readonly
                                clickable
                                v-model="room"
                                label="房号:"
                                name="room"
                                :rules="[{ required: true, message: '请选择房号：' }]"
                                class="repair_input"
                                @click="showPickerArea = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <!-- 选择区域、楼号、楼层、序号 -->
                        <van-popup v-model="showPickerArea" round position="bottom">
                            <van-picker
                                show-toolbar
                                :columns="columns"
                                @confirm="onConfirmArea"
                                @cancel="showPicker = false"
                            />
                        </van-popup>
                        <van-col span="24">
                            <van-field
                                rows="3"
                                autosize
                                type="textarea"
                                v-model="content"
                                label="问题描述:"
                                name="content"
                                :rules="[{ required: true, message: '请输入您的需求' }]"
                                class="repair_input repair_textarea"
                            />
                        </van-col>
                        <van-col span="24">
                            <div class="photo_upload">
                                <span class="photo_left font-26 color-6">上传照片:</span>
                                <div class="photo_right">
                                    <van-uploader v-model="photo_list" multiple />
                                </div>
                            </div>
                        </van-col>
                    </van-row>
                </div>
                <div class="status_box">
                    <h3 class="font-28 color-3">完成状态</h3>
                    <p class="font-28 color-6">完成状态</p>
                    <van-field
                        readonly
                        v-model="status"
                        name="status"
                        :class="['repair_input', status == '待处理' ? 'pending' : (status == '处理中' || '已完成，待确认') ? 'processing' : status == '待评价' ? 'evaluated' : '']"
                    />
                </div>
                <div class="status_box">
                    <p class="font-28 color-6">综合评价</p>
                    <van-rate
                        readonly
                        v-model="star_num"
                        :icon="star_select"
                        :void-icon="star_default"
                        class="star"
                    />
                    <p class="font-28 color-6">其他</p>
                    <van-field
                        readonly
                        rows="3"
                        autosize
                        type="textarea"
                        v-model="evaluated_content"
                        name="evaluated"
                        class="repair_input repair_textarea"
                    />
                </div>
                <div class="bot_height">
                    <div class="bot_button">
                        <van-button class="custom_btn" round block>确认</van-button>
                        <!-- <van-button class="custom_btn" round block>去评价</van-button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'reservation_detail',
    data () {
        return {
            service_name: '水电维修',
            service_time: '2023-2-13',
            repair_name: '厨房没电',
            name: '张伟',
            phone: '13498573847',
            area: '南区',
            building: '5号',
            floor: '10层',
            room: '1023号',
            columns: ['区域1', '区域2', '区域3', '区域4', '区域5'],
            showPickerArea: false, // 区域、楼号、楼层、房号选择
            content: '这是一段描述',
            photo_list: [
                { url: require('@/assets/upload/photo.png') },
                { url: require('@/assets/upload/photo.png') },
                { url: require('@/assets/upload/photo.png') },
                { url: require('@/assets/upload/photo.png') }
            ],
            status: '待评价',
            star_num: 3,
            star_select: require('@/assets/icon/star_select.png'),
            star_default: require('@/assets/icon/star.png'),
            evaluated_content: '暂无'

        }
    },
    methods: {
        onConfirmArea(values) {
            this.area = values
            this.showPickerArea = false;
        },
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.detail{    
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
}
.repair_form{
    padding: .3rem .2rem;
    border-radius: .1rem;
    margin-bottom: .3rem;
    background-color: #FFFFFF;
}
.repair_name{
    line-height: 1.4;
    padding-bottom: .2rem;
    font-weight: normal;
}
.repair_input{
    display: block;
    font-size: .28rem;
    color: #666666;
    line-height: .8rem;
    padding: 0;
    margin-bottom: .4rem;
    &:active{
        background-color: transparent;
    }
    ::v-deep .van-field__label{
        width: 100%;
        line-height: 2;
        margin: .1rem 0;
        color: #666666;
    }
    ::v-deep .van-field__body{
        width: 100%;
        height: .8rem;
        border-radius: .08rem;
        border: .02rem solid #EEEEEE;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .76rem;
        padding: 0 .2rem;
        border-radius: .08rem;
    }
    ::v-deep .van-field__control:disabled{
        color: #999999;
        -webkit-text-fill-color: #999999;
    }
    ::v-deep .van-field__button{
        padding-right: .1rem;
    }
    ::v-deep .van-field__button img{
        width: .32rem;
        height: .32rem;
        object-fit: contain;
        vertical-align: middle;
    }
    ::v-deep .van-field__error-message{
        font-size: .2rem;
        line-height: 1.4;
        margin-top: .1rem;
        padding: 0 .2rem;
    }
}
.repair_textarea{
    ::v-deep .van-field__body{
        width: 100%;
        height: auto;
        border-radius: .08rem;
        border: .02rem solid #DFDFDF;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .4rem;
        padding: .2rem;
        border-radius: .08rem;
    }
}
.pending ::v-deep .van-field__control{
    color: #00B0F0;
}
.processing ::v-deep .van-field__control{
    color: #39DCAB;
}
.evaluated ::v-deep .van-field__control{
    color: #F8B142;
}
.photo_upload{
    margin-bottom: .4rem;
    .photo_left{
        display: block;
        line-height: 2;
        margin-bottom: .15rem;
    }
    .photo_right{
        overflow: visible;
        ::v-deep .van-uploader{
            margin-bottom: -.46rem;
        }
        ::v-deep .van-uploader__preview{
            margin-right: .46rem;
            margin-bottom: .46rem;
        }
        ::v-deep .van-uploader__preview-image{
            width: 1.6rem;
            height: 1.6rem;
        }
        ::v-deep .van-uploader__preview-delete{
            top: -.16rem;
            right: -.16rem;
            width: .32rem;
            height: .32rem;
            border-radius: 50%;
            background-color: #4E9DF6;
        }
        ::v-deep .van-uploader__preview-delete-icon{
            font-size: .32rem;
            top: 0;
            right: 0;
            transform: scale(0.6);
        }
        ::v-deep .van-uploader__upload{
            width: 1.6rem;
            height: 1.6rem;
            margin-right: .46rem;
            margin-bottom: .46rem;
        }
        ::v-deep .van-uploader__upload-icon{
            font-size: .64rem;
        }
    }
    .photo_default{
        width: 1.6rem;
        height: 1.6rem;
        border-radius: .08rem;
        border: .02rem solid #D4D5D6;
        img{
            width: .56rem;
            height: .56rem;
            object-fit: contain;
            margin: 0 auto .1rem;
        }
    }
} 

.status_box{
    line-height: 2;
    padding: .3rem .2rem;
    margin-bottom: .3rem;
    border-radius: .1rem;
    background-color: #FFFFFF;
    .repair_input{
        margin-bottom: 0;
    }
}
.star{
    margin: .1rem 0 .25rem;
    ::v-deep .van-rate__item{
        margin-right: .4rem;
    }
    ::v-deep .van-rate__icon{
        width: .48rem;
        height: .48rem;
        font-size: 0;
    }
    ::v-deep .van-icon__image{
        width: 100%;
        height: 100%;
    }
}
.bot_height{
    height: 1.5rem;
    height: calc(1.5rem + constant(safe-area-inset-bottom));
    height: calc(1.5rem + env(safe-area-inset-bottom));
}
</style>