<template>
    <div class="container">
        <navbar title="消息详情" bgColor="#F7F7F7"></navbar>
        <div class="detail">
            <div class="wrap">
                <div class="repair_form">
                    <van-row type="flex">
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="name"
                                label="姓名:"
                                name="name"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="phone"
                                label="phone:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="11">
                            <van-field
                                disabled
                                clickable
                                v-model="area"
                                label="区域:"
                                name="area"
                                class="repair_input"
                                @click="showPickerArea = true"                            >                                
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11" offset="2">
                            <van-field
                                disabled
                                clickable
                                v-model="building"
                                label="楼号:"
                                name="building"
                                class="repair_input"
                                @click="showPickerArea = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11">
                            <van-field
                                disabled
                                clickable
                                v-model="floor"
                                label="楼层:"
                                name="floor"
                                :rules="[{ required: true, message: '请选择楼层：' }]"
                                class="repair_input"
                                @click="showPickerArea = true"
                            >                                
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11" offset="2">
                            <van-field
                                disabled
                                clickable
                                v-model="room"
                                label="房号:"
                                name="room"
                                :rules="[{ required: true, message: '请选择房号：' }]"
                                class="repair_input"
                                @click="showPickerArea = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="reservation_items"
                                label="预约项目:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                disabled
                                v-model="reservation_time"
                                label="预约时间:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                    </van-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'reservation_detail',
    data () {
        return {
            name: '张伟',
            phone: '13498573847',
            area: '南区',
            building: '5号',
            floor: '10层',
            room: '1023号',
            reservation_items: '房屋维修',
            reservation_time: '2023-2-13  8:00~10:00'

        }
    },
    methods: {
        
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.detail{    
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
}
.repair_form{
    padding: .3rem .2rem;
    border-radius: .1rem;
    margin-bottom: .3rem;
    background-color: #FFFFFF;
}
.repair_name{
    line-height: 1.4;
    padding-bottom: .2rem;
    font-weight: normal;
}
.repair_input{
    display: block;
    font-size: .28rem;
    color: #666666;
    line-height: .8rem;
    padding: 0;
    margin-bottom: .4rem;
    &:active{
        background-color: transparent;
    }
    ::v-deep .van-field__label{
        width: 100%;
        line-height: 2;
        margin: .1rem 0;
        color: #666666;
    }
    ::v-deep .van-field__body{
        width: 100%;
        height: .8rem;
        border-radius: .08rem;
        border: .02rem solid #EEEEEE;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .76rem;
        padding: 0 .2rem;
        border-radius: .08rem;
    }
    ::v-deep .van-field__control:disabled{
        color: #999999;
        -webkit-text-fill-color: #999999;
    }
    ::v-deep .van-field__button{
        padding-right: .1rem;
    }
    ::v-deep .van-field__button img{
        width: .32rem;
        height: .32rem;
        object-fit: contain;
        vertical-align: middle;
    }
    ::v-deep .van-field__error-message{
        font-size: .2rem;
        line-height: 1.4;
        margin-top: .1rem;
        padding: 0 .2rem;
    }
}
.repair_textarea{
    ::v-deep .van-field__body{
        width: 100%;
        height: auto;
        border-radius: .08rem;
        border: .02rem solid #DFDFDF;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .4rem;
        padding: .2rem;
        border-radius: .08rem;
    }
}
.pending ::v-deep .van-field__control{
    color: #00B0F0;
}
.processing ::v-deep .van-field__control{
    color: #39DCAB;
}
.evaluated ::v-deep .van-field__control{
    color: #F8B142;
}
.photo_upload{
    margin-bottom: .4rem;
    .photo_left{
        display: block;
        line-height: 2;
        margin-bottom: .15rem;
    }
    .photo_right{
        ::v-deep .van-uploader{
            margin-bottom: -.46rem;
        }
        ::v-deep .van-uploader__preview{
            margin-right: .46rem;
            margin-bottom: .46rem;
        }
        ::v-deep .van-uploader__preview-image{
            width: 1.6rem;
            height: 1.6rem;
        }
        ::v-deep .van-uploader__preview-delete{
            top: -.16rem;
            right: -.16rem;
            width: .32rem;
            height: .32rem;
            border-radius: 50%;
            background-color: #4E9DF6;
        }
        ::v-deep .van-uploader__preview-delete-icon{
            font-size: .32rem;
            top: 0;
            right: 0;
            transform: scale(0.6);
        }
        ::v-deep .van-uploader__upload{
            width: 1.6rem;
            height: 1.6rem;
            margin-right: .46rem;
            margin-bottom: .46rem;
        }
        ::v-deep .van-uploader__upload-icon{
            font-size: .64rem;
        }
    }
    .photo_default{
        width: 1.6rem;
        height: 1.6rem;
        border-radius: .08rem;
        border: .02rem solid #D4D5D6;
        img{
            width: .56rem;
            height: .56rem;
            object-fit: contain;
            margin: 0 auto .1rem;
        }
    }
} 

.status_box{
    line-height: 2;
    padding: .3rem .2rem;
    margin-bottom: .3rem;
    border-radius: .1rem;
    background-color: #FFFFFF;
    .repair_input{
        margin-bottom: 0;
    }
}
.star{
    margin: .1rem 0 .25rem;
    ::v-deep .van-rate__item{
        margin-right: .4rem;
    }
    ::v-deep .van-rate__icon{
        width: .48rem;
        height: .48rem;
        font-size: 0;
    }
    ::v-deep .van-icon__image{
        width: 100%;
        height: 100%;
    }
}
.bot_height{
    height: 1.5rem;
    height: calc(1.5rem + constant(safe-area-inset-bottom));
    height: calc(1.5rem + env(safe-area-inset-bottom));
}
</style>