<template>
    <div class="home container">
        <navbar title="中国气象科技园区物业运维公众号" :leftArrow="false" bgColor="#F7F7F7"></navbar>
        <div class="banner">
            <img :src="banner && banner.pic" alt="">
        </div>
        <div class="home_notice">
            <div class="wrap">
                <div class="home_notice_left font-20 color-f text-center">最新公告</div>
                <div class="home_notice_right font-26 color-6">
                    <van-swipe class="home_notice_swipe" :autoplay="3000" vertical :show-indicators="false">
                        <van-swipe-item class="flex justify-between align-center"
                                        v-for="(item, index) in notice"
                                        :key="index"
                                        @click="toNoticeDetail(item.id)"
                        >
                            <span class="ellipsis">{{item.content}}</span>
                            <van-icon name="arrow" color="#C8C8C8" />
                        </van-swipe-item>

                    </van-swipe>
                </div>
            </div>
        </div>
        <div class="home_top">
            <div class="wrap">
                <ul class="home_top_list flex justify-between">
                    <li>
                        <router-link to="/merchants_list" class="home_top_link">
                            <div class="home_top_icon">
                                <img src="@/assets/icon/home_icon_01.png" alt="">
                            </div>
                            <h3 class="home_top_title font-24 color3 ellipsis text-center">园区招商</h3>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/booking" class="home_top_link">
                            <div class="home_top_icon">
                                <img src="@/assets/icon/home_icon_02.png" alt="">
                            </div>
                            <h3 class="home_top_title font-24 color3 ellipsis text-center">预约服务</h3>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/bill" class="home_top_link">
                            <div class="home_top_icon">
                                <img src="@/assets/icon/home_icon_03.png" alt="">
                            </div>
                            <h3 class="home_top_title font-24 color3 ellipsis text-center">查询账单</h3>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/visitor" class="home_top_link">
                            <div class="home_top_icon">
                                <img src="@/assets/icon/home_icon_04.png" alt="">
                            </div>
                            <h3 class="home_top_title font-24 color3 ellipsis text-center">访客通行</h3>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="home_bot">
            <div class="wrap">
                <div class="home_bot_title flex justify-between align-center">
                    <h2 class="font-30 color-3">园区招商</h2>
                    <van-icon size=".3rem" name="arrow" color="#C8C8C8" />
                </div>
                <van-tabs 
                    v-model="active"
                    color="#4E9DF6"
                    background="transparent"
                    line-width=".56rem"
                    line-height=".06rem"
                    title-active-color="#4E9DF6"
                    title-inactive-color="#666666"
                    :ellipsis="false"
                    class="home_bot_tab"
                    @click="tabChange"
                >
                    <van-tab title="店铺出租" name="店铺出租1"></van-tab>
                    <van-tab title="商用出租" name="2"></van-tab>
                    <van-tab title="仓库出租" name="3"></van-tab>
                    <van-tab title="车位出租" name="4"></van-tab>
                </van-tabs>
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    class="home_bot_list"
                >
                    <li class="home_bot_item">
                        <router-link class="home_bot_link flex" to="/">
                            <div class="home_bot_img"><img src="@/assets/upload/home_bot_img.png" alt=""></div>
                            <div class="home_bot_text">
                                <h3 class="home_bot_tit font-28 color-3 ellipsis">中国气象科技园旺铺出租，急招…</h3>
                                <div class="home_bot_des font-24 color-6 van-multi-ellipsis--l2">
                                    <p>地址：北京市丰台区汉威国际大厦二号楼一单元2034…</p>
                                </div>
                            </div>
                        </router-link>
                    </li>
                    <li class="home_bot_item">
                        <router-link class="home_bot_link flex" to="/">
                            <div class="home_bot_img"><img src="@/assets/upload/home_bot_img.png" alt=""></div>
                            <div class="home_bot_text">
                                <h3 class="home_bot_tit font-28 color-3 ellipsis">中国气象科技园旺铺出租，急招…</h3>
                                <div class="home_bot_des font-24 color-6 van-multi-ellipsis--l2">
                                    <p>地址：北京市丰台区汉威国际大厦二号楼一单元2034…</p>
                                </div>
                            </div>
                        </router-link>
                    </li>
                    <li class="home_bot_item">
                        <router-link class="home_bot_link flex" to="/">
                            <div class="home_bot_img"><img src="@/assets/upload/home_bot_img.png" alt=""></div>
                            <div class="home_bot_text">
                                <h3 class="home_bot_tit font-28 color-3 ellipsis">中国气象科技园旺铺出租，急招…</h3>
                                <div class="home_bot_des font-24 color-6 van-multi-ellipsis--l2">
                                    <p>地址：北京市丰台区汉威国际大厦二号楼一单元2034…</p>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </van-list>
            </div>
        </div>
        <Tabbar :current="0"></Tabbar>
    </div>
</template>

<script>
import index from '@/api/index'
import Tabbar from '@/components/Tabbar.vue'
export default {
    name: 'Home',
    components: { Tabbar },
    data() {
        return {
            active: 0,
            list: [],
            loading: false,
            finished: false,
            banner: {},
            notice: [{}, {}]
        }
    },
  created() {
    this.getBanner()
    this.getNotice()
  },
    methods: {
        // 园区招商切换
        tabChange(name) {
            this.$toast(name)
        },
      // 获取用户信息
      getInfo() {
        this.$store.dispatch('GetInfo').then((res) => {
          console.log(res)
          this.userInfo = res
        })
      },
      getBanner() {
        index.banner().then((res) => {
          if (res.status == 0) {
            this.banner = res.data
          }
        })
      },
      toNoticeDetail(id) {
          console.log(id)
        this.$router.push('/notice_detail?id=' + id)
      },
      getNotice() {
        index.notice().then((res) => {
          if (res.status == 0) {
            this.notice = res.data
          }
        })
      },
        onLoad() {
            
        }
    }
}
</script>

<style lang="scss" scoped>
.banner{
    height: 5rem;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.home_notice{
    padding: .3rem 0;
    line-height: .4rem;
    .home_notice_left{
        float: left;
        width: 1rem;
        height: .4rem;
        line-height: .4rem;
        background: #4E9DF6;
        border-radius: .06rem;
        margin-right: .2rem;
    }
    .home_notice_right{
        height: .4rem;
        overflow: hidden;
        .home_notice_swipe{
            height: .4rem;
            .van-icon{
                flex-shrink: 0;
                margin-left: .2rem;
            }
        }
    }
}
.home_top{
    .home_top_list{
        margin: 0 -.15rem;
        li{
            padding: .15rem;
        }
        .home_top_title{
            line-height: 2;
            font-weight: normal;
        }
    }
}
.home_bot{
    .home_bot_title{
        line-height: 1.6;
        margin: .15rem 0;
    }
    .home_bot_tab{
        margin-bottom: .3rem;
        ::v-deep .van-tabs__nav{
            justify-content: space-between;
            margin: 0 -.1rem;
            padding: 0;
            &.van-tabs__nav--line{
                padding-bottom: .1rem;
            }
            .van-tab{
                flex: none;
                line-height: 1.8;
                padding: 0 .1rem;
                .van-tab__text{
                    font-size: .28rem;
                }
            }
            .van-tabs__line{
                bottom: 0;
            }
        }
        ::v-deep .van-tabs__wrap{
            height: auto;
        }
    }
    .home_bot_list{
        list-style: none;
        overflow: hidden;
        li{
            margin-bottom: .3rem;
            .home_bot_link{
                background: #FFFFFF;
                border-radius: .16rem;
                padding: .3rem .2rem .3rem .3rem;
                overflow: hidden;
                .home_bot_img{
                    flex-shrink: 0;
                    width: 1.76rem;
                    height: 1.36rem;
                    margin-right: .3rem;
                    img{
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .home_bot_text{
                    flex: auto;
                    overflow: hidden;
                    .home_bot_tit{
                        line-height: 1.6;
                    }
                    .home_bot_des{
                        line-height: 1.6;
                        margin-top: .1rem;
                    }
                }
            }
        }
    }
    
    
}
</style>
