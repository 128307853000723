<template>
    <div>
        <div class="tabbar-placeholder" v-if="!keyboard"></div>
        <div class="tabbar" v-if="!keyboard">
            <router-link class="tabbar-item" replace to="/property" @click="changeTabbar(0)">
                <img
                    v-show="current == 0"
                    src="@/assets/icon/tab_home_select.png"
                    class="tabbar-item-img"
                    alt
                />
                <img v-show="current != 0" src="@/assets/icon/tab_home.png" class="tabbar-item-img" alt />
                <span :class="['tabbar-item-text', current == 0 ? 'active' : '']">首页</span>
            </router-link>
            <router-link class="tabbar-item" replace to="/property_news" @click="changeTabbar(1)">
                <img
                    v-show="current == 1"
                    src="@/assets/icon/tab_news_select.png"
                    class="tabbar-item-img"
                    alt
                />
                <img v-show="current != 1" src="@/assets/icon/tab_news.png" class="tabbar-item-img" alt />
                <span :class="['tabbar-item-text', current == 1 ? 'active' : '']">消息</span>
            </router-link>
            <router-link class="tabbar-item" replace to="/property_order" @click="changeTabbar(2)">
                <img v-show="current == 2" src="@/assets/icon/tab_order_select.png" class="tabbar-item-img" alt />
                <img v-show="current != 2" src="@/assets/icon/tab_order.png" class="tabbar-item-img" alt />
                <span :class="['tabbar-item-text', current == 2 ? 'active' : '']">工单</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    props: {
        current: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState([
            'keyboard'
        ])
    },
    methods: {
        changeTabbar(index) {
            this.current = index
        }
    }
}
</script>

<style scoped>
.tabbar {
  height: 1.36rem;
  width: 100%;
  box-shadow: 0px -0.04rem 0.12rem 0px rgba(92, 102, 153, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  left: 0;
  right: 0;
  max-width: 750px;
  margin: 0 auto;
  bottom: -1px;
  background-color: #fff;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 10;
}
.tabbar-placeholder {
  height: 1.36rem;
}
.tabbar-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.tabbar-item-img {
  width: 0.54rem;
  height: 0.52rem;
}
.tabbar-item-text {
  display: block;
  font-size: 0.22rem;
  font-weight: 400;
  color: #666666;
  margin-top: .04rem;
}
.active {
  font-weight: 500 !important;
  color: #4E9DF6 !important;
}
</style>