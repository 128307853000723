<template>
    <div class="detail_box container">
        <navbar title="消息详情"></navbar>
        <div class="detail_text">
            <div class="wrap">
                <div class="detail_top">
                    <h1 class="detail_tit font-28 color-3">{{info.name}}</h1>
                    <span class="detail_time font-22 color-9">{{info.created_day}}</span>
                </div>
                <div class="detail_con font-26 color-6">
                    <p>{{info.content}}</p>
                </div>
            </div>
        </div>
        <div class="bot_button">
            <van-button class="custom_btn" round block type="info">已阅</van-button>
        </div>
    </div>
</template>
<script>
import notice from '@/api/notice';
export default {
    name: 'NoticeDdetail',
    data () {
        return {
          id: '',
          info: {},
          content: ''
        }
    },
  created() {
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail() {
      notice.getDetail({id: this.id}).then((res) => {
        if (res.status == 0) {
          this.info = res.data
          const regex = new RegExp('<img', 'gi')
          this.content = this.info.content.replace(regex, `<img style="max-width: 100%; height: auto"`);

        }
      })
    }
  },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.detail_box{
    min-height: 100vh;
    background-color: #FFFFFF;
}
.detail_text{
    background-color: #FFFFFF;
    margin-bottom: 1.5rem;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
    .detail_top{
        padding: .1rem 0;
        border-bottom: .02rem solid rgba($color: #000000, $alpha: 0.05);
        overflow: hidden;
        .detail_tit{
            margin: .2rem 0;
        }
        .detail_time{
            display: block;
            margin: .2rem 0;
        }
    }
    .detail_con{
        line-height: 1.8;
        margin: .2rem 0 .6rem;
    }
}
</style>
