<template>
    <div class="password container">
        <navbar title="修改密码"></navbar>
        <van-form ref="form" @submit="onSubmit" class="login_form">
            <label class="label_text">手机号</label>
            <van-field
                v-model="phone"
                type="tel"
                name="phone"
                placeholder="请输入手机号"
                :rules="userPhone"
                class="input_box gray"
            />
            <label class="label_text">验证码</label>
            <van-field
                v-model="sms"
                placeholder="请输入验证码"
                type="digit"
                :rules="userSms"
                class="input_box gray"
            >
                <template #button>
                    <van-button
                        @click="sendCode"
                        :disabled="disabled"
                        native-type="button"
                        class="sms_btn sms_btn2"
                    >
                        {{codeMsg}}
                    </van-button>
                </template>
            </van-field>
            <div class="bot_button">
                <van-button class="custom_btn" round block type="info" native-type="submit">下一步</van-button>
                <van-checkbox v-model="checked" class="agree">
                    登录代表您已阅读并同意<span>《用户协议》</span><span>及</span><span>《隐私协议》</span>
                    <template #icon="props">
                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                    </template>
                </van-checkbox>
            </div>
        </van-form>
    </div>
</template>
<script>
export default {
    name: 'Password',
    data () {
        return {
            phone: '',
            sms: '',
            disabled: false,
            codeNum: 6,
            codeMsg: "发送验证码",
            userPhone: [
                {
                    required: true,
                    message: "请输入手机号",
                    trigger: "onBlur"
                },
                {
                    validator: value => {
                        return /^1[23456789]\d{9}$/.test(value);
                    },
                    message: "请输入正确格式的手机号"
                }
            ],
            userSms:[
                {
                    required: true,
                    message: "请输入验证码",
                    trigger: "onBlur"
                },
                {
                    validator: value => {
                        return /^\d{6}$/.test(value);
                    },
                    message: "验证码格式错误，请检查！"
                }
            ],
            checked: true,
            activeIcon: require('@/assets/icon/check.png'),
            inactiveIcon: require('@/assets/icon/check_select.png'),
        }
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    .login_form{
        max-width: 6rem;
        margin: 0 auto;
        padding-top: .6rem;
    }
    .login_btn{
        color: #4E9DF6;
        background-color: rgba($color: #FFFFFF, $alpha: 0.85);
    }
    .agree{
        font-size: .26rem;
        color: #FFFFFF;
        padding-left: .6rem;
        margin: .3rem 0;
        ::v-deep .van-checkbox__icon{
            width: .32rem;
            height: .32rem;
            font-size: 0;
        }
        ::v-deep .van-checkbox__label{
            color: rgba($color: #FFFFFF, $alpha: 0.5);
            line-height: .32rem;
            white-space: nowrap;
            span{
                color: #FFFFFF;
            }
        }
    }
</style>
