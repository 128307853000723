<template>
  <div class="login container">
    <div class="login_title font-48 color-f text-center">
      <p>中国气象科技园运维公众号</p>
    </div>
    <van-form ref="loginForm" :model="loginForm" @submit="onSubmit" class="login_form">
      <van-field
          v-model="loginForm.mobile"
          type="tel"
          name="mobile"
          placeholder="请输入手机号"
          :rules="userPhone"
          class="input_box"
      />
      <van-field
          v-model="loginForm.captcha"
          placeholder="请输入验证码"
          type="digit"
          :rules="userSms"
          class="input_box"
      >
        <template #button>
          <van-button
              @click="sendCode"
              :disabled="disabled"
              native-type="button"
              class="sms_btn"
          >
            丨 {{ codeMsg }}
          </van-button>
        </template>
      </van-field>
      <div class="for_pwd">
        <router-link to="/password">忘记密码？</router-link>
      </div>
      <div class="bot_button">
        <van-button class="custom_btn login_btn" round block type="info" native-type="submit">登录</van-button>
        <van-checkbox v-model="checked" class="agree">
          登录代表您已阅读并同意<span>《用户协议》</span><span>及</span><span>《隐私协议》</span>
          <template #icon="props">
            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
          </template>
        </van-checkbox>
      </div>
    </van-form>
  </div>
</template>
<script>
import {Toast} from 'vant'
import {mapGetters} from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      mobile: '',
      captcha: '',
      disabled: false,
      codeNum: 60,
      codeMsg: "发送验证码",
      loginForm: {
        mobile: '',
        captcha: ''
      },
      userPhone: [
        {
          required: true,
          message: "请输入手机号",
          trigger: "onBlur"
        },
        {
          validator: value => {
            return /^1[23456789]\d{9}$/.test(value);
          },
          message: "请输入正确格式的手机号"
        }
      ],
      userSms: [
        {
          required: true,
          message: "请输入验证码",
          trigger: "onBlur"
        },
        {
          validator: value => {
            return /^\d{6}$/.test(value);
          },
          message: "验证码格式错误，请检查！"
        }
      ],
      checked: false,
      activeIcon: require('@/assets/icon/check_select.png'),
      inactiveIcon: require('@/assets/icon/check.png'),
    }
  },
  computed: {
    ...mapGetters(['userType'])
  },

  methods: {
    // 获取短信验证码
    sendCode() {
      if (!this.loginForm.mobile) {
        this.$toast('请输入手机号！')
        return
      }
      // 验证手机号格式是否正确
      if (!this.userPhone[1].validator(this.loginForm.mobile)) return;
      // 禁用按钮
      this.disabled = true;
      // 倒计时
      let timer = setInterval(() => {
        --this.codeNum;
        this.codeMsg = `重新发送(${this.codeNum})`;
      }, 1000);
      // 判断什么时候停止定时器
      setInterval(() => {
        clearInterval(timer);
        this.codeNum = 60;
        this.disabled = false;
        this.codeMsg = "获取短信验证码";
      }, 6000);
    },
    asyncValidator() {

    },
    onSubmit() {
      if (!this.checked) {
        this.$toast('请勾选用户协议！')
        return
      }
      this.$store.dispatch('Login', this.loginForm)
          .then(() => {
              console.log('fff')
            console.log('dd'+ this.userType)
            if (this.userType === 0)
              window.location.href = '/'
            else
              window.location.href = '/property'

          }).catch(err => {
        console.log(err)
        Toast.fail(err.message || '用户名或密码错误');
      }).finally(function () {
        console.log(9999)
      })
    },

  },
  created() {
  },
  mounted() {
  },
}
</script>
<style lang="scss" scoped>
.login {
  min-height: 100vh;
  background: url('~@/assets/bg/login_bg.png') center center no-repeat;
  background-size: cover;
}

.login_title {
  padding: 2.4rem 1.5rem .8rem;
}

.login_form {
  max-width: 6rem;
  margin: 0 auto;
}

.for_pwd {
  text-align: right;
  font-size: .24rem;
  color: #FFFFFF;
  margin-top: -.15rem;

  a {
    color: #FFFFFF;
  }
}

.login_btn {
  color: #4E9DF6;
  background-color: rgba($color: #FFFFFF, $alpha: 0.85);
}

.agree {
  font-size: .26rem;
  color: #FFFFFF;
  padding-left: .6rem;
  margin: .3rem 0;

  ::v-deep .van-checkbox__icon {
    width: .32rem;
    height: .32rem;
    font-size: 0;
  }

  ::v-deep .van-checkbox__label {
    color: rgba($color: #FFFFFF, $alpha: 0.5);
    line-height: .32rem;
    white-space: nowrap;

    span {
      color: #FFFFFF;
    }
  }
}
</style>