<template>
    <div class="container">        
        <navbar title="我的账单" bgColor="#F7F7F7"></navbar>
        <div class="tab_bg tab_bg2">
            <div class="wrap">
                <van-tabs 
                    v-model="active"
                    color="#4E9DF6"
                    background="transparent"
                    line-width=".56rem"
                    line-height=".06rem"
                    title-active-color="#4E9DF6"
                    title-inactive-color="#666666"
                    :ellipsis="false"
                    class="tab_list"
                    @click="tabChange"
                >
                    <van-tab title="全部" name="1"></van-tab>
                    <van-tab title="待处理" name="2"></van-tab>
                    <van-tab title="已完成" name="3"></van-tab>
                </van-tabs>
            </div>
            <div class="month flex align-center">
                <span class="font-28 color-3">月份</span>
                <van-field
                    readonly
                    clickable
                    name="month"
                    :value="month"
                    @click="showPickerMouth = true"
                >
                    <template #button>
                        <img src="@/assets/icon/select.png" alt="">
                    </template>
                </van-field>
                <van-popup v-model="showPickerMouth" position="bottom">
                    <van-picker
                        show-toolbar
                        :columns="monthList"
                        @confirm="onConfirmMouth"
                        @cancel="onConfirmMouth = false"
                    />
                </van-popup>
            </div>
        </div>
        <div class="list">
            <div class="wrap">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    class="notice_list"
                >
                    <!-- 待支付 添加class=pending 已支付添加class="over" -->
                    <div class="notice_item over">
                        <router-link class="notice_link font-24 color-6" to="/bill_detail">
                            <div class="notice_top">
                                <h3 class="notice_tit font-28">2023年1月份账单</h3>
                                <p class="ellipsis">您的订单39247270972当前已付款，请您及时查收！</p>
                            </div>
                            <div class="notice_bot text-right color-9">
                                应付：<span class="price font-36 color-m">¥3218.56</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="notice_item pending">
                        <router-link class="notice_link font-24 color-6" to="/bill_detail">
                            <div class="notice_top">
                                <h3 class="notice_tit font-28">2023年2月份账单</h3>
                                <p class="ellipsis">您的订单39247270972当前已付款，请您及时查收！</p>
                            </div>
                            <div class="notice_bot text-right">
                                应付：<span class="price font-36 color-m">¥3218.56</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="notice_item over">
                        <router-link class="notice_link font-24 color-6" to="/bill_detail">
                            <div class="notice_top">
                                <h3 class="notice_tit font-28 flex justify-between">2023年3月份账单<span class="font-24 color-9">缴费时间：2021-10-10</span></h3>
                                <p class="ellipsis">您的订单39247270972当前已付款，请您及时查收！</p>
                            </div>
                            <div class="notice_bot text-right">
                                实付：<span class="price font-36 color-m">¥3218.56</span>
                            </div>
                        </router-link>
                    </div>
                </van-list>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'bill',
    data () {
        return {
            active: 2,
            month: '',
            monthList: ['01月', '02月', '03月', '04月', '05月', '06月', '07月', '08月', '09月', '10月', '11月', '12月'],
            showPickerMouth: false,
            list: [],
            loading: false,
            finished: false,
        }
    },
    methods: {
        // 切换
        tabChange(name) {
            this.$toast(name)
        },
        onConfirmMouth(value) {
            this.month = value;
            this.showPickerMouth = false;
        },
        onLoad() {
            
        }
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.month{
    position: absolute;
    right: .3rem;
    bottom: .1rem;
    span{
        flex-shrink: 0;
        margin-right: .12rem;
    }
    .van-cell{
        width: 1.44rem;
        height: .48rem;
        padding: 0;
        padding-left: .2rem;
        font-size: .2rem;
    }
    ::v-deep .van-field__button{
        width: .32rem;
        height: .32rem;
        padding: 0;
        margin: 0 .1rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.list{
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}
.notice_list{
    list-style: none;
    overflow: hidden;
    .notice_item{
        position: relative;
        margin-bottom: .3rem;
        &::before{
            display: block;
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: .9rem;
            height: .9rem;
            background-position: right top;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &.over::before{
            background-image: url('~@/assets/icon/over.png');
        }
        &.pending::before{
            background-image: url('~@/assets/icon/pending.png');
        }
        .notice_link{
            display: block;
            background: #FFFFFF;
            border-radius: .16rem;
            padding: 0 .3rem;
            overflow: hidden;
            .notice_top{
                line-height: 1.8;
                padding: .25rem 0;
                .notice_tit{
                    line-height: 1.8;
                    padding-right: .3rem;
                    span{
                        font-weight: normal;
                    }
                }
            }
            .notice_bot{
                line-height: .54rem;
                padding: .2rem 0;
                border-top: .02rem dashed #E9E9E9;
                .price{
                    font-weight: bold;
                }
            }
        }
    }
}
</style>