<template>
    <div class="container">
        <navbar title="访客通行" bgColor="#F7F7F7"></navbar>
        <div class="detail">
            <div class="wrap">
                <van-form @submit="onSubmit">
                    <div class="repair_form">
                        <van-row type="flex">
                            <van-col span="24">
                                <van-field
                                    v-model="name"
                                    label="姓名"
                                    name="name"
                                    placeholder="请输入姓名"
                                    :rules="[{ required: true, message: '请输入姓名' }]"
                                    class="repair_input"
                                />
                            </van-col>
                            <van-col span="24">
                                <van-field
                                    v-model="phone"
                                    label="电话:"
                                    name="phone"
                                    placeholder="请输入联系方式"
                                    :rules="[{ required: true, message: '请输入联系方式' }]"
                                    class="repair_input"
                                />
                            </van-col>                        
                            <van-col span="24">
                                <van-field
                                    readonly
                                    clickable
                                    v-model="sex"
                                    label="性别"
                                    name="sex"
                                    placeholder="请选择性别"
                                    :rules="[{ required: true, message: '请选择性别' }]"
                                    class="repair_input"
                                    @click="showPickerSex = true"
                                >
                                    <template #button>
                                        <img src="@/assets/icon/select.png" alt="">
                                    </template>
                                </van-field>
                            </van-col>
                            <van-col span="24">
                                <van-field
                                    readonly
                                    clickable
                                    v-model="visit_time"
                                    label="到访时间"
                                    name="visit_time"
                                    class="repair_input"
                                    placeholder="请选择到访时间"
                                    :rules="[{ required: true, message: '请选择到访时间' }]"
                                    @click="showPickerTime = true"
                                >
                                    <template #button>
                                        <img src="@/assets/icon/select.png" alt="">
                                    </template>
                                </van-field>
                            </van-col>
                            <van-col span="24">
                                <van-field
                                    readonly
                                    clickable
                                    v-model="visit_room"
                                    label="到访房间号"
                                    name="visit_room"
                                    class="repair_input"
                                    placeholder="请选择房间号"
                                    :rules="[{ required: true, message: '请选择房间号' }]"
                                    @click="showPickerRoom = true"
                                >
                                    <template #button>
                                        <img src="@/assets/icon/select.png" alt="">
                                    </template>
                                </van-field>
                            </van-col>
                            <van-col span="24">
                                <van-field
                                    readonly
                                    v-model="interviewee"
                                    label="被访人姓名:"
                                    name="interviewee"
                                    placeholder="请输入被访人姓名"
                                    :rules="[{ required: true, message: '请输入被访人姓名' }]"
                                    class="repair_input"
                                />
                            </van-col>
                        </van-row>
                        <!-- 选择性别 -->
                        <van-popup v-model="showPickerSex" round position="bottom">
                            <van-picker
                                show-toolbar
                                title="请选择您的性别"
                                :columns="sexItem"
                                :default-index="0"
                                @cancel="showPickerSex = false"
                                @confirm="onConfirmSex"
                            />
                        </van-popup>                    
                        <!-- 选择时间 -->
                        <van-popup v-model="showPickerTime" round position="bottom">
                            <van-datetime-picker
                                type="datetime"                            
                                :min-date="minDate"
                                :max-date="maxDate"
                                @confirm="onConfirmTime"
                                @cancel="showPickerTime = false"
                            />
                        </van-popup>
                        <!-- 到访房间号 -->
                        <van-popup v-model="showPickerRoom" round position="bottom">
                            <van-picker
                                show-toolbar
                                :columns="roomItem"
                                @confirm="onConfirmRoom"
                                @cancel="showPickerRoom = false"
                            />
                        </van-popup>                        
                    </div>
                    <div class="bot_height">
                        <div class="bot_button flex justify-between align-center">
                            <van-button class="custom_btn plain_btn" round block>邀请访客</van-button>
                            <van-button class="custom_btn" round block  native-type="submit">提交</van-button>
                        </div>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'reservation_detail',
    data () {
        return {
            name:'',
            phone: '',
            showPickerSex: false,
            sex: '',
            sexItem: ['男', '女'],
            visit_time: '',
            showPickerTime: false,
            minDate: new Date(2023, 1, 1),
            maxDate: new Date(2025, 10, 1),
            roomItem: ['101','102'],
            showPickerRoom: false,
            visit_room: '',
            interviewee: ''
        }
    },
    methods: {
        onConfirmSex(values){
            this.sex = values
            this.showPickerSex = false;
        },
        onConfirmTime(values){
            let that = this ;
            this.visit_time = that.utils.formatTime(values,'-',5)
            this.showPickerTime = false;
        },
        onConfirmRoom(values) {
            this.visit_room = values
            this.showPickerRoom = false;
        },
        onSubmit(values) {
            console.log('submit', values);
        },
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.detail{    
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
}
.repair_form{
    padding: .3rem .2rem;
    border-radius: .1rem;
    margin-bottom: .3rem;
    background-color: #FFFFFF;
}
.repair_name{
    line-height: 1.4;
    padding-bottom: .2rem;
    font-weight: normal;
}
.repair_input{
    display: block;
    font-size: .28rem;
    color: #666666;
    line-height: .8rem;
    padding: 0;
    margin-bottom: .4rem;
    &:active{
        background-color: transparent;
    }
    ::v-deep .van-field__label{
        width: 100%;
        line-height: 2;
        margin: .1rem 0;
        color: #666666;
    }
    ::v-deep .van-field__body{
        width: 100%;
        height: .8rem;
        border-radius: .08rem;
        border: .02rem solid #EEEEEE;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .76rem;
        padding: 0 .2rem;
        border-radius: .08rem;
    }
    ::v-deep .van-field__control:disabled{
        color: #999999;
        -webkit-text-fill-color: #999999;
    }
    ::v-deep .van-field__button{
        padding-right: .1rem;
    }
    ::v-deep .van-field__button img{
        width: .32rem;
        height: .32rem;
        object-fit: contain;
        vertical-align: middle;
    }
    ::v-deep .van-field__error-message{
        font-size: .2rem;
        line-height: 1.4;
        margin-top: .1rem;
        padding: 0 .2rem;
    }
}
.repair_textarea{
    ::v-deep .van-field__body{
        width: 100%;
        height: auto;
        border-radius: .08rem;
        border: .02rem solid #DFDFDF;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .4rem;
        padding: .2rem;
        border-radius: .08rem;
    }
}
.pending ::v-deep .van-field__control{
    color: #00B0F0;
}
.processing ::v-deep .van-field__control{
    color: #39DCAB;
}
.evaluated ::v-deep .van-field__control{
    color: #F8B142;
}
.photo_upload{
    margin-bottom: .4rem;
    .photo_left{
        display: block;
        line-height: 2;
        margin-bottom: .15rem;
    }
    .photo_right{
        ::v-deep .van-uploader{
            margin-bottom: -.46rem;
        }
        ::v-deep .van-uploader__preview{
            margin-right: .46rem;
            margin-bottom: .46rem;
        }
        ::v-deep .van-uploader__preview-image{
            width: 1.6rem;
            height: 1.6rem;
        }
        ::v-deep .van-uploader__preview-delete{
            top: -.16rem;
            right: -.16rem;
            width: .32rem;
            height: .32rem;
            border-radius: 50%;
            background-color: #4E9DF6;
        }
        ::v-deep .van-uploader__preview-delete-icon{
            font-size: .32rem;
            top: 0;
            right: 0;
            transform: scale(0.6);
        }
        ::v-deep .van-uploader__upload{
            width: 1.6rem;
            height: 1.6rem;
            margin-right: .46rem;
            margin-bottom: .46rem;
        }
        ::v-deep .van-uploader__upload-icon{
            font-size: .64rem;
        }
    }
    .photo_default{
        width: 1.6rem;
        height: 1.6rem;
        border-radius: .08rem;
        border: .02rem solid #D4D5D6;
        img{
            width: .56rem;
            height: .56rem;
            object-fit: contain;
            margin: 0 auto .1rem;
        }
    }
} 

.status_box{
    line-height: 2;
    padding: .3rem .2rem;
    margin-bottom: .3rem;
    border-radius: .1rem;
    background-color: #FFFFFF;
    .repair_input{
        margin-bottom: 0;
    }
}
.star{
    margin: .1rem 0 .25rem;
    ::v-deep .van-rate__item{
        margin-right: .4rem;
    }
    ::v-deep .van-rate__icon{
        width: .48rem;
        height: .48rem;
        font-size: 0;
    }
    ::v-deep .van-icon__image{
        width: 100%;
        height: 100%;
    }
}
.bot_height{
    height: 1.5rem;
    height: calc(1.5rem + constant(safe-area-inset-bottom));
    height: calc(1.5rem + env(safe-area-inset-bottom));
}
</style>