<template>
    <div class="container white_bg">        
        <navbar title="操作成功"></navbar>
        <div class="success flex flex-col justify-center align-center">
            <div class="success_img"><img src="@/assets/icon/success.png" alt=""></div>
            <h3 class="font-34 color-0">操作成功</h3>
        </div>
        <div class="bot_button">
            <van-button class="custom_btn" round block>确定</van-button>
        </div>
    </div>
</template>
<script>
export default {
name: 'repair_success',
    data () {
        return {
        }
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    .white_bg{
        min-height: 100vh;
        padding-bottom: 1.8rem;
        padding-bottom: calc(1.8rem + constant(safe-area-inset-bottom));
        padding-bottom: calc(1.8rem + env(safe-area-inset-bottom));
    }
    .success{
        min-height: 60vh;
    }
    .success_img{
        width: 4.28rem;
        height: 3.2rem;
        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
</style>