<template>
    <div class="header" :style="{backgroundColor:bgColor}">
        <van-nav-bar :title="title" :left-arrow="leftArrow" :border="false" @click-left="back"></van-nav-bar>
    </div>
</template>

<script>
export default {
    props: {
        bgColor:{
            type: String,
            default:'#FFFFFF'
        },
        title: {
            type: String,
            default: ''
        },
        leftArrow:{
            type: Boolean,
            default: true
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="scss">
    .header{
        position: sticky;
        top: 0;
        z-index: 10;
    }
    .van-nav-bar{
        line-height: .9rem;
        background-color: transparent;
    }
    .van-nav-bar__content{
        height: .9rem;
    }
    .van-nav-bar__title{
        max-width: 70%;
        font-size: .34rem;
        font-weight: bold;
    }
    .van-nav-bar .van-icon{
        color: #000000;
    }
    .van-nav-bar__arrow{
        font-size: .3rem;
    }
</style>