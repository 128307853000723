<template>
    <div class="container">
        <navbar title="设备报修"></navbar>
        <div class="banner">
            <img src="@/assets/upload/merchants_banner.png" alt="">
        </div>
        <div class="detail_info">
            <div class="detail_info_top">
                <div class="wrap">
                    <h1 class="detail_name font-30 color-3">设备报修 办公室电器用品报修…</h1>
                    <div class="detail_time flex justify-between align-center font-24 color-9">
                        <span>招商时间：2023.2~12</span>
                        <span>热线电话：<strong>010-867382409</strong></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail_text">
            <div class="wrap">
                <h2 class="detail_tit font-28 color-9 text-center"><span>服务介绍</span></h2>
                <div class="detail_con font-26 color-6">
                    <p>风靡好莱坞的冰激凌防晒，位居DMK全球畅销榜单第三名。
                        硬核防晒，看得见的实力！好莱坞亲测有效，清爽不油腻，质地轻薄好吸收。要美白，先防晒。DMK防晒霜，帮你随时开启轻装出行模式。外出旅行、户外运动、海边度假，轻轻一抹，让你无惧炎炎烈日。夏日里，也可以养成BABY肌，DMK晒后舒缓嗜喱。</p>
                </div>
            </div>
        </div>
        <div class="repair_box">
            <div class="wrap">
                <h2 class="repair_tit font-30 color-3">报修服务</h2>
                <van-form @submit="onSubmit" class="repair_form">
                    <van-row type="flex">
                        <van-col span="24">
                            <van-field
                                v-model="username"
                                label="姓名:"
                                name="name"
                                placeholder="请输入姓名"
                                autocomplete="off"
                                :rules="[{ required: true, message: '请输入您的姓名' }]"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                v-model="mobile"
                                label="电话:"
                                name="mobile"
                                autocomplete="off"
                                placeholder="请输入联系方式"
                                :rules="[{ required: true, message: '请输入您的联系方式' }]"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="11">
                            <van-field
                                readonly
                                clickable
                                v-model="districtName"
                                label="区域:"
                                name="districtName"
                                :rules="[{ required: true, message: '请选择区域：' }]"
                                class="repair_input"
                                @click="showPickerDistrict = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11" offset="2">
                            <van-field
                                readonly
                                clickable
                                v-model="buildingName"
                                label="楼号:"
                                name="name"
                                :rules="[{ required: true, message: '请选择楼号：' }]"
                                class="repair_input"
                                @click="showPickerBuilding = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11">
                            <van-field
                                readonly
                                clickable
                                v-model="floorName"
                                label="楼层:"
                                name="name"
                                :rules="[{ required: true, message: '请选择楼层：' }]"
                                class="repair_input"
                                @click="showPickerFloor = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11" offset="2">
                            <van-field
                                readonly
                                clickable
                                v-model="roomName"
                                label="房号:"
                                name="name"
                                :rules="[{ required: true, message: '请选择房号：' }]"
                                class="repair_input"
                                @click="showPickerRoom = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                clickable
                                v-model="typeName"
                                label="预约项目:"
                                name="name"
                                :rules="[{ required: true, message: '请输入选择预约项目' }]"
                                class="repair_input"
                                @click="showPickerType = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>

                        <van-col span="24">
                            <van-field
                                v-model="name"
                                label="概要报修名称:"
                                name="name"
                                :rules="[{ required: true, message: '请输入您要报修的名称：' }]"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                clickable
                                v-model="appointment_time"
                                label="预约时间:"
                                name="name"
                                placeholder="请选择预约时间"
                                :rules="[{ required: true, message: '请选择您想要的上门时间：' }]"
                                class="repair_input"
                                @click="showPickerTime = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                clickable
                                v-model="typeName"
                                label="预约项目:"
                                name="name"
                                :rules="[{ required: true, message: '请输入选择预约项目' }]"
                                class="repair_input"
                                @click="showPickerType = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="24">
                            <h3 class="repair_name font-28 color-6">是否自带配件：</h3>
                            <van-radio-group v-model="isParts" direction="horizontal" class="custom_radio">
                                <van-radio name="1">
                                    是
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? radioIcon : radioIcon2"/>
                                    </template>
                                </van-radio>
                                <van-radio name="2">
                                    否
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? radioIcon : radioIcon2"/>
                                    </template>
                                </van-radio>
                            </van-radio-group>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                rows="3"
                                autosize
                                type="textarea"
                                v-model="content"
                                label="描述:"
                                name="content"
                                :rules="[{ required: true, message: '请输入您的需求' }]"
                                class="repair_input repair_textarea"
                            />
                        </van-col>
                        <van-col span="24">
                            <div class="photo_upload flex">
                                <span class="photo_left font-26 color-6">上传照片:</span>
                                <div class="photo_right">
                                    <van-uploader
                                        v-model="picList"
                                        :after-read="OnAfterRead"
                                        :before-read="onBeforeRead"
                                        @delete="onDelete"
                                        :max-count="4"
                                        accept="image/*"
                                        @oversize="onOversize"
                                    >
                                        <div
                                            class="photo_default flex flex-col align-center justify-center font-24 color-6">
                                            <img src="@/assets/icon/photo.png" alt="">
                                            <p>照片</p>
                                        </div>
                                    </van-uploader>
                                </div>
                            </div>
                        </van-col>
                    </van-row>
                    <!-- 选择区域、楼号、楼层、序号 -->
                    <van-popup v-model="showPickerDistrict" round position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="district"
                            @confirm="onConfirmDistrict"
                            @cancel="onCancelDistrict"
                        >
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.name }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>
                    <van-popup v-model="showPickerBuilding" round position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="building"
                            @confirm="onConfirmBuilding"
                            @cancel="onCancelBuilding"
                        >
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.name }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>
                    <van-popup v-model="showPickerFloor" round position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="floor"
                            @confirm="onConfirmFloor"
                            @cancel="onCancelFloor"
                        >
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.name }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>
                    <van-popup v-model="showPickerRoom" round position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="room"
                            @confirm="onConfirmRoom"
                            @cancel="onCancelRoom"
                        >
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.name }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>
                    <!-- 选择时间 -->
                    <van-popup v-model="showPickerTime" round position="bottom">
                        <van-datetime-picker
                            type="datetime"
                            :min-date="minDate"
                            :max-date="maxDate"
                            @confirm="onConfirmTime"
                            @cancel="showPickerTime = false"
                        />
                    </van-popup>
                    <!-- 选择维修类型 -->
                    <van-popup v-model="showPickerType" round position="bottom">
                        <van-tree-select
                            height="5.95rem"
                            :items="projectType"
                            :main-active-index.sync="typeIndex"
                            @click-nav="leftType"
                            @click-item="rightType"
                            class="custom_tree"
                        >
                        </van-tree-select>
                    </van-popup>
                    <van-button class="custom_btn" round block type="info" native-type="submit">提交</van-button>
                </van-form>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import booking from '@/api/booking'
import axios from "axios";
import utils from "@/utils/utils";
export default {
    name: 'repair',
    data() {
        return {
            name: '',
            username: '',
            mobile: '',
            district_id: '',
            districtName: '',
            district: [],
            building: [],
            buildingName: '',
            building_id: '',
            floor: [],
            room: [],
            floor_id: '',
            floorName: '',
            room_id: '',
            roomName: '',
            repair_name: '',
            appointment_time: '', //上门时间
            type: '',
            typeName: '',
            isParts: '1',
            content: '',
            picList: [],
            urlList:[],
            isShowLoading: false,
            showPickerDistrict: false,
            showPickerBuilding: false, // 区域、楼号、楼层、房号选择
            showPickerFloor: false,
            showPickerRoom: false,
            showPickerTime: false, //上门时间选择
            minDate: new Date(2023, 1, 1),
            maxDate: new Date(2025, 10, 1),
            showPickerType: false, //维修类型
            typeIndex: 0,
            projectType: [],
            root: [],
            tableSelect: [],

            radioIcon: require('@/assets/icon/check_blue.png'),
            radioIcon2: require('@/assets/icon/check_gray.png'),
        }
    },
    computed: {
        ...mapGetters(['token'])
    },
    methods: {
        OnAfterRead(file) {
            let formData = new FormData();
            formData.append('file', file.file);
            console.log(formData)
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            };

            //上传图片
             axios.post("http://ql.1092cc.cn/api/upload/upload", formData, config).then(res => {

                 if (res.status === 200) {
                    let img  = JSON.parse(JSON.stringify(res.data.data))
                    console.log(img)

                    //this.picList = JSON.stringify(this.picList)
                   this.picList.concat({"url":img})
                     this.urlList.push(img)
                    // this.urlList = this.urlList+img+','
                     console.log(this.urlList)

                } else {
                    this.$toast('上传失败');
                }
            })

        },


        onBeforeRead(file) {
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
                return true
            }
            this.$toast.fail('请上传正确格式的图片！')
            return false
        },

        onOversize() {
            this.$toast("图片大小不能超过 2M");
        },


        onDelete(file, detail) {
            this.photo.splice(detail.index, 1)
            this.$toast.success('删除成功')
        },

        onConfirmDistrict(value, index) {
            this.showPickerDistrict = false
            this.district_id = this.district[index].id
            this.districtName = this.district[index].name
        },
        onCancelDistrict() {
            this.showPickerDistrict = false
        },
        onConfirmBuilding(value, index) {
            this.showPickerBuilding = false
            this.building_id = this.building[index].id
            this.buildingName = this.building[index].name
        },
        onCancelBuilding() {
            this.showPickerBuilding = false
        },
        onConfirmFloor(value, index) {
            this.showPickerFloor = false
            this.floor_id = this.floor[index].id
            this.floorName = this.floor[index].name
        },
        onCancelFloor() {
            this.showPickerFloor = false
        },
        onConfirmRoom(value, index) {
            this.showPickerRoom = false
            this.room_id = this.room[index].id
            this.roomName = this.room[index].name
        },
        onCancelRoom() {
            this.showPickerRoom = false
        },
        onConfirmTime(values) {
            let that = this;
            this.appointment_time = that.utils.formatTime(values, '-', 5)
            this.showPickerTime = false;
        },
        onConfirmType() {
            this.showPickerType = false;
            console.log(22)
        },
        // 维修类型左
        leftType(index) {
            this.typeIndex = index
        },
        // 维修类型右
        rightType(data) {
            this.typeName = data.text
            this.typeIndex = data.activeId
            console.log(this.type + this.typeName)
            this.showPickerType = false;
        },
        getDistrict() {
            booking.district().then((res) => {
                if (res.status == 0) {
                    this.district = res.data
                }
            })
        },
        getBuilding() {
            booking.building().then((res) => {
                if (res.status === 0) {
                    this.building = res.data
                }
            })
        },
        getFloor() {
            booking.floor().then((res) => {
                if (res.status === 0) {
                    this.floor = res.data
                }
            })
        },
        getRoom() {
            booking.room().then((res) => {
                if (res.status === 0) {
                    this.room = res.data
                }
            })
        },
        getRoot() {
            booking.root().then((res) => {
                if (res.status === 0) {
                    res.data.forEach(v => {
                        this.root.push(
                            {
                                text: v.name,   // items里面的对象属性必须是text和activeId
                                activeId: v.id
                            }
                        )
                    })
                }
            })
        },
        onNavClick(index) {
            const id = this.root[index].activeId // 点击分类传入当前下标获取该分类的ID

            booking.subType({pid: id}).then((res) => {
                if (res.status === 0) {
                    this.tableSelect = res.data
                }
            })
        },

        getProjectType() {
            booking.projectType().then((res) => {
                if (res.status === 0) {
                    this.projectType = res.data
                }
            })
        },

        onSubmit() {
            var that = this
            this.params = {
                type: 1,
                username: this.username,
                mobile: this.mobile,
                district_id: this.district_id,
                building_id: this.building_id,
                floor_id: this.floor_id,
                room_id: this.room_id,
                project_type_id: this.typeIndex ?? 0,
                appointment_time: this.appointment_time,
                pic: this.urlList,
                content: this.content,
                is_self_fittings: this.isParts,
                name: this.name
            }

            booking.submitRepair(this.params).then((res) => {
                if (res.status === 0) {
                    this.$toast('提交成功')
                    setTimeout(function () {
                        that.$router.go(-1);
                    }, 500)
                } else {
                    this.$toast('提交失败')
                }
            })
        },

    },

    created() {
        this.type = this.$route.query.type
        if (!this.token) {
            this.$toast.fail('你还未登录')
            setTimeout(function () {
                window.location.href = '/login'
            }, 500)
            return false
        }

        this.getRoot()
        this.getDistrict()
        this.getBuilding()
        this.getFloor()
        this.getRoom()
        this.getProjectType()

        this.appointment_time = utils.getTime()

        this.minDate = new  Date()
        console.log(this.minDate)
        this.maxDate  = new Date(new Date().getFullYear() + 5, 11, 31)

        console.log(this.picList)
    },
    mounted() {
        setTimeout(()=>{
//代码
        },800)
    },
}
</script>
<style lang="scss" scoped>
.banner {
    height: 6.6rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.detail_info {
    .detail_info_top {
        margin-bottom: .3rem;
        background-color: #FFFFFF;
        overflow: hidden;

        .detail_name {
            line-height: 1.6;
            margin: .2rem 0 .1rem;
        }

        .detail_time {
            line-height: 1.8;
            margin: .1rem 0;

            strong {
                color: #4E9DF6;
                font-weight: normal;
            }
        }
    }

    .detail_info_bot {
        li {
            line-height: .5rem;
            padding: .25rem .3rem;
            margin-bottom: .3rem;
            background-color: #FFFFFF;
            overflow: hidden;

            span {
                float: left;
                color: #999999;
                margin-right: .2rem;
            }

            p {
                text-align: right;
                overflow: hidden;

                &.org {
                    color: #F8B142;
                }
            }
        }
    }
}

.detail_text {
    background-color: #FFFFFF;
    margin-bottom: .3rem;
    overflow: hidden;

    .detail_tit {
        line-height: .7rem;
        margin: .2rem 0;
        font-weight: normal;
        overflow: hidden;

        span {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            padding: 0 .3rem;

            &::before,
            &::after {
                position: absolute;
                top: 50%;
                margin-top: -1px;
                display: block;
                content: "";
                width: 2rem;
                height: .02rem;
            }

            &::before {
                right: 100%;
                background: linear-gradient(90deg, #F2F5F7 0%, #BDC2C5 100%);
            }

            &::after {
                left: 100%;
                background: linear-gradient(243deg, #F2F5F7 0%, #BDC2C5 100%);

            }
        }
    }

    .detail_con {
        line-height: 1.8;
        margin: .2rem 0 .6rem;
    }
}

.repair_box {
    background-color: #FFFFFF;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
}

.repair_tit {
    line-height: 1.6;
    margin: .3rem 0;
}

.repair_name {
    line-height: 1.4;
    padding-bottom: .2rem;
    font-weight: normal;
}

.repair_input {
    font-size: .26rem;
    color: #666666;
    line-height: .88rem;
    padding: 0;
    margin-bottom: .4rem;

    &:active {
        background-color: transparent;
    }

    ::v-deep .van-field__label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        width: auto;
        // min-width: 1.08rem;
        width: 1.08rem;
        width: .68rem;
        height: .9rem;
        line-height: .3rem;
        color: #666666;
    }

    ::v-deep .van-field__body {
        width: 100%;
        height: .88rem;
        border-radius: .08rem;
        border: .02rem solid #DFDFDF;
        background: #FFFFFF;
    }

    ::v-deep .van-field__control {
        line-height: .84rem;
        padding: 0 .2rem;
        border-radius: .08rem;
    }

    ::v-deep .van-field__control:disabled {
        color: #999999;
        -webkit-text-fill-color: #999999;
    }

    ::v-deep .van-field__button {
        padding-right: .1rem;
    }

    ::v-deep .van-field__button img {
        width: .32rem;
        height: .32rem;
        object-fit: contain;
        vertical-align: middle;
    }

    ::v-deep .van-field__error-message {
        font-size: .2rem;
        line-height: 1.4;
        margin-top: .1rem;
        padding: 0 .2rem;
    }
}

.repair_textarea {
    ::v-deep .van-field__body {
        width: 100%;
        height: auto;
        border-radius: .08rem;
        border: .02rem solid #DFDFDF;
        background: #FFFFFF;
    }

    ::v-deep .van-field__control {
        line-height: .4rem;
        padding: .2rem;
        border-radius: .08rem;
    }
}

.photo_upload {
    margin-bottom: .4rem;

    .photo_left {
        display: block;
        flex-shrink: 0;
    }

    .photo_right {
        flex: auto;
        overflow: hidden;
    }

    .photo_default {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: .08rem;
        border: .02rem solid #D4D5D6;
        margin-left: .3rem;

        img {
            width: .56rem;
            height: .56rem;
            object-fit: contain;
            margin: 0 auto .1rem;
        }
    }
}
</style>