<template>
    <div class="container">
        <navbar title="评价" bgColor="#F7F7F7"></navbar>
        <van-form @submit="onSubmit" class="info_list">
            <div class="wrap">
                <div class="status_box">
                    <p class="status_title font-28 color-6">综合评价</p>
                    <van-rate
                        v-model="star_num"
                        :icon="star_select"
                        :void-icon="star_default"
                        class="star"
                        @change="starChange"
                    />
                </div>
                <div class="status_box">
                    <p class="status_title font-28 color-6">其他</p>
                    <van-field
                        rows="3"
                        autosize
                        type="textarea"
                        v-model="evaluated_content"
                        placeholder="请输入需要补充的相关信息"
                        name="evaluated"
                        class="repair_input repair_textarea"
                    />
                </div>
                <div class="bot_height">
                    <div class="bot_button">
                        <van-button class="custom_btn gradual" round block native-type="submit">提交</van-button>
                    </div>
                </div>
            </div>
        </van-form>
    </div>
</template>
<script>
export default {
    name: 'reservation_evaluated',
    data () {
        return {
            status: '待评价',
            star_num: 3,
            star_select: require('@/assets/icon/star_select.png'),
            star_default: require('@/assets/icon/star.png'),
            evaluated_content: ''
        }
    },
    methods: {
        starChange(value){
            this.star_num = value
        },
        onSubmit(values) {
            console.log('star_num', this.star_num);
            console.log('submit', values.evaluated);
        },
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.status_box{
    line-height: 2;
    padding: .3rem;
    margin-bottom: .3rem;
    border-radius: .1rem;
    background-color: #FFFFFF;
    .status_title{
        margin: 0 0 .2rem;
    }
    .repair_input{
        margin-bottom: 0;
    }
}
.star{
    margin: .1rem 0 .25rem;
    ::v-deep .van-rate__item{
        margin-right: .4rem;
    }
    ::v-deep .van-rate__icon{
        width: .48rem;
        height: .48rem;
        font-size: 0;
    }
    ::v-deep .van-icon__image{
        width: 100%;
        height: 100%;
    }
}
.repair_input{
    display: block;
    font-size: .28rem;
    color: #666666;
    line-height: .8rem;
    padding: 0;
    margin-bottom: .4rem;
    &:active{
        background-color: transparent;
    }
    ::v-deep .van-field__label{
        width: 100%;
        line-height: 2;
        margin: .1rem 0;
        color: #666666;
    }
    ::v-deep .van-field__body{
        width: 100%;
        height: .8rem;
        border-radius: .08rem;
        border: .02rem solid #EEEEEE;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .76rem;
        padding: 0 .2rem;
        border-radius: .08rem;
    }
    ::v-deep .van-field__control:disabled{
        color: #999999;
        -webkit-text-fill-color: #999999;
    }
    ::v-deep .van-field__button{
        padding-right: .1rem;
    }
    ::v-deep .van-field__button img{
        width: .32rem;
        height: .32rem;
        object-fit: contain;
        vertical-align: middle;
    }
    ::v-deep .van-field__error-message{
        font-size: .2rem;
        line-height: 1.4;
        margin-top: .1rem;
        padding: 0 .2rem;
    }
}
.repair_textarea{
    ::v-deep .van-field__body{
        width: 100%;
        height: auto;
        border-radius: .08rem;
        border: .02rem solid #DFDFDF;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .4rem;
        padding: .2rem;
        border-radius: .08rem;
    }
}
</style>