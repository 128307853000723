<template>
  <div id="app" style="min-height: 100vh;">
    <router-view v-wechat-title="$route.meta.title" />
  </div>
</template>

<script>
// import wx from 'weixin-js-sdk'
// import Api from '@/request/api'
export default {
  mounted() {
   this.$store.dispatch('player/mount', this.$el)
    // let ua = navigator.userAgent.toLowerCase();
    // if (/iphone|ipad|ipod/.test(ua)) {
    //   url = window.location.href.split('#')[0];
    // } else if (/android/.test(ua)) {
    //   url = window.location.href;
    // }
    // let url = '';
    // url = window.location.href;
    // Api.getWx({ url }).then(res => {
    //   wx.config({
    //     debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //     appId: res.appId, // 必填，公众号的唯一标识
    //     timestamp: res.timestamp, // 必填，生成签名的时间戳
    //     nonceStr: res.nonceStr, // 必填，生成签名的随机串
    //     signature: res.signature, // 必填，签名
    //     jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表
    //   });
    //   wx.error(function (err) {
    //     alert("出错了：" + err.errMsg);//这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
    //   });
    // })
  }
}
</script>

<style lang="scss">
  #app{
    background-color: #F7F7F7;
  }
</style>
