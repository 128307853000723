<template>
    <div class="list_box container">
        <navbar title="园区招商" bgColor="#F7F7F7"></navbar>
        <div class="tab_bg">
            <div class="wrap">
                <van-tabs
                    v-model="active"
                    color="#4E9DF6"
                    background="transparent"
                    line-width=".56rem"
                    line-height=".06rem"
                    title-active-color="#4E9DF6"
                    title-inactive-color="#666666"
                    :ellipsis="false"
                    class="tab_list"
                    @click="tabChange"
                >
                    <van-tab title="店铺出租" name="1"></van-tab>
                    <van-tab title="商用出租" name="2"></van-tab>
                    <van-tab title="仓库出租" name="3"></van-tab>
                    <van-tab title="车位出租" name="4"></van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="list">
            <div class="wrap">
                <div v-if="list && list.length>0">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :immediate-check="false"
                    finished-text="没有更多了"
                    @load="onLoad"
                    class="home_bot_list"
                >
                    <li class="home_bot_item"
                        v-for="(item, index) in list"
                        :key="index"
                        @click="handleToDetail(item.id)"
                    >
                        <router-link class="home_bot_link flex" to="/merchants_detail">
                            <div class="home_bot_img"><img src="@/assets/upload/home_bot_img.png" alt=""></div>
                            <div class="home_bot_text">
                                <h3 class="home_bot_tit font-28 color-3 ellipsis">{{item.name}}</h3>
                                <div class="home_bot_des font-24 color-6 van-multi-ellipsis--l2">
                                    <p>地址：{{item.address}}</p>
                                </div>
                            </div>
                        </router-link>
                    </li>

                </van-list>
                    </div>
                <div v-else>
                    <div style="width:100%;min-height: 300px;text-align: center;line-height: 300px;display:flex;justify-content: center">暂无数据</div>
                </div>
            </div>
        </div>

         </div>
</template>

<script>
import merchant from '@/api/merchant'
export default {
    name: 'MerchantsList',
    data() {
        return {
            active: 0,
            type_id: 1,
            list: [],
            loading: false,
            finished: false,
            page: 1,
            more: true,
            total: 0
        };
    },
    created() {
        this.getList()
    },
    methods: {
        // 园区招商切换
        tabChange(idx) {
            this.$toast(idx)
            this.type_id = idx
            this.page = 1
            this.list = []
            this.more = true
            this.getList()
        },
        onLoad() {
            this.page++;
            this.getList();
        },

        handleToDetail(id) {
            this.$router.push('/merchants_detail?id=' + id);
        },

        getList() {
            merchant.getList({
                page: this.page,
                type_id: this.type_id
            }).then((res) => {
                if (res.status == 0) {
                    var list = res.data.list

                    this.loading = false;
                    this.total = res.data.total;
                    if(list == null ||list.length === 0) {
                        //加载结束
                        this.finished = true;
                        console.log('sfsdf')
                        return;
                    }
                    this.list = this.list.concat(list)
                    this.total = res.data.total
                    if(this.list.length >= this.total) {
                        this.finished = true;
                    }
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.tab_bg{
    position: sticky;
    top: .9rem;
    background-color: #F7F7F7;
    z-index: 10;
    .tab_list{
        margin-bottom: .3rem;
        ::v-deep .van-tabs__nav{
            justify-content: space-between;
            margin: 0 -.1rem;
            padding: 0;
            &.van-tabs__nav--line{
                padding-bottom: .1rem;
            }
            .van-tab{
                flex: none;
                line-height: 1.8;
                padding: 0 .1rem;
                .van-tab__text{
                    font-size: .28rem;
                }
            }
            .van-tabs__line{
                bottom: 0;
            }
        }
        ::v-deep .van-tabs__wrap{
            height: auto;
        }
    }
}
.home_bot_list{
    list-style: none;
    overflow: hidden;
    li{
        margin-bottom: .3rem;
        .home_bot_link{
            background: #FFFFFF;
            border-radius: .16rem;
            padding: .3rem .2rem .3rem .3rem;
            overflow: hidden;
            .home_bot_img{
                flex-shrink: 0;
                width: 1.76rem;
                height: 1.36rem;
                margin-right: .3rem;
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .home_bot_text{
                flex: auto;
                overflow: hidden;
                .home_bot_tit{
                    line-height: 1.6;
                }
                .home_bot_des{
                    line-height: 1.6;
                    margin-top: .1rem;
                }
            }
        }
    }
}
</style>