import { render, staticRenderFns } from "./receipt_detail.vue?vue&type=template&id=37a62734&scoped=true&"
import script from "./receipt_detail.vue?vue&type=script&lang=js&"
export * from "./receipt_detail.vue?vue&type=script&lang=js&"
import style0 from "./receipt_detail.vue?vue&type=style&index=0&id=37a62734&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a62734",
  null
  
)

export default component.exports