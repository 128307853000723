<template>
    <div class="container">
        <navbar title="工单" :leftArrow="false" bgColor="#F7F7F7"></navbar>
        <div class="tab_bg tab_bg2">
            <div class="wrap">
                <van-tabs
                    v-model="status"
                    color="#4E9DF6"
                    background="transparent"
                    line-width=".56rem"
                    line-height=".06rem"
                    title-active-color="#4E9DF6"
                    title-inactive-color="#666666"
                    :ellipsis="false"
                    class="tab_list"
                    @click="tabChange"
                >
                    <van-tab title="全部" name="1"></van-tab>
                    <van-tab title="待处理工单" name="2"></van-tab>
                    <van-tab title="历史工单" name="3"></van-tab>
                </van-tabs>
                <div class="search">
                    <van-search
                        v-model="keyword"
                        shape="round"
                        placeholder="请输入名称进行查询"
                        @input="inKey"
                    >
                        <template #right-icon>
                            <img src="@/assets/icon/search.png" alt="">
                        </template>
                    </van-search>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="wrap">
                <div v-if="list && list.length>0">
                    <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                        class="notice_list"
                    >
                        <!-- 待支付 添加class=pending 已支付添加class="over" -->
                        <div class="notice_item pending">
                            <router-link class="notice_link font-24 color-6" to="/bill_detail">
                                <div class="notice_top">
                                    <h3 class="notice_tit font-28">房屋水电维修</h3>
                                    <p class="ellipsis">报修厨房电路损坏，入户电有问题。电路老化…</p>
                                </div>
                                <div class="notice_bot color-9">服务时间：2021-10-10</div>
                            </router-link>
                        </div>
                        <div class="notice_item over">
                            <router-link class="notice_link font-24 color-6" to="/bill_detail">
                                <div class="notice_top">
                                    <h3 class="notice_tit font-28">房屋水电维修</h3>
                                    <p class="ellipsis">报修厨房电路损坏，入户电有问题。电路老化…</p>
                                </div>
                                <div class="notice_bot color-9">服务时间：2021-10-10</div>
                            </router-link>
                        </div>
                        <div class="notice_item pending">
                            <router-link class="notice_link font-24 color-6" to="/bill_detail">
                                <div class="notice_top">
                                    <h3 class="notice_tit font-28">房屋水电维修</h3>
                                    <p class="ellipsis">报修厨房电路损坏，入户电有问题。电路老化…</p>
                                </div>
                                <div class="notice_bot color-9">服务时间：2021-10-10</div>
                            </router-link>
                        </div>
                    </van-list>
                    </div>
                    <div v-else>
                        <div
                            style="width:100%;min-height: 300px;text-align: center;line-height: 300px;display:flex;justify-content: center">
                            暂无数据
                        </div>
                    </div>
                </div>

            </div>
        </div>
</template>
<script>
import order from "@/api/order";
import {mapGetters} from "vuex";

export default {
    name: 'bill',
    data() {
        return {
            active: 2,
            keyword: '',
            status: 0,
            list: [],
            loading: false,
            finished: false,
            page: 1,
            more: true,
            total: 0
        }
    },
    computed: {
        ...mapGetters(['userType'])

    },
    methods: {
        inKey(value) {
            this.key = value
        },
        tabChange(idx) {
            this.$toast(idx)
            this.type_id = idx
            this.page = 1
            this.list = []
            this.more = true
            this.getList()
        },
        onLoad() {
            this.page++;
            this.getList();
        },

        handleToDetail(id) {
            this.$router.push('/property_order_detail?id=' + id);
        },

        getList() {
            order.getList({
                page: this.page,
                status: this.status,
                keyword: this.keyword
            }).then((res) => {
                if (res.status === 0) {
                    let list = res.data.list

                    this.loading = false;
                    this.total = res.data.total;
                    if (list == null || list.length === 0) {
                        this.finished = true;
                        return;
                    }
                    this.list = this.list.concat(list)
                    this.total = res.data.total
                    if (this.list.length >= this.total) {
                        this.finished = true;
                    }
                }
            })
        }
    },
    created() {
        this.status = this.$route.query.status

        console.log(this.userType)
        if (this.userType !== 1) {
            this.$toast.fail('无权限')
            return false
        }
        this.getList()
    },
    mounted() {
    },
}
</script>
<style lang="scss" scoped>
.month {
    position: absolute;
    right: .3rem;
    bottom: .1rem;

    span {
        flex-shrink: 0;
        margin-right: .12rem;
    }

    .van-cell {
        width: 1.44rem;
        height: .48rem;
        padding: 0;
        padding-left: .2rem;
        font-size: .2rem;
    }

    ::v-deep .van-field__button {
        width: .32rem;
        height: .32rem;
        padding: 0;
        margin: 0 .1rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.list {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}

.notice_list {
    list-style: none;
    overflow: hidden;

    .notice_item {
        position: relative;
        margin-bottom: .3rem;

        &::before {
            display: block;
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: .9rem;
            height: .9rem;
            background-position: right top;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &.over::before {
            background-image: url('~@/assets/icon/over.png');
        }

        &.pending::before {
            background-image: url('~@/assets/icon/pending.png');
        }

        .notice_link {
            display: block;
            background: #FFFFFF;
            border-radius: .16rem;
            padding: 0 .3rem;
            overflow: hidden;

            .notice_top {
                line-height: 1.8;
                padding: .25rem 0;

                .notice_tit {
                    line-height: 1.8;
                    padding-right: .3rem;

                    span {
                        font-weight: normal;
                    }
                }
            }

            .notice_bot {
                line-height: .54rem;
                padding: .2rem 0;
                border-top: .02rem dashed #E9E9E9;

                .price {
                    font-weight: bold;
                }
            }
        }
    }
}
</style>