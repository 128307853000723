import { get,post,upload } from '@/utils/request'

export default {
    //区域
    district(params = {}) {
        return get('/appointment/district', { params })
    },
    // 楼栋
    building(params = {}) {
        return get('/appointment/building', { params })
    },
    floor(params = {}) {
        return get('/appointment/floor', { params })
    },
    room(params = {}) {
        return get('/appointment/room', { params })
    },
    root(params = {}) {
        return get('/appointment/root', { params })
    },
    projectType(params = {}) {
        return get('/appointment/project_type', { params })
    },
    subType(params) {
        return get('/appointment/getSubType', { params })
    },
    submit(data) {
        return post('/appointment/submit', data)
    },
    submitRepair(data) {
        return post('/appointment/submit', data)
    },
    upload(data) {
        return upload('/upload/upload', data)
    },
}
