<template>
    <div class="container">
        <navbar title="接受工单" bgColor="#F7F7F7"></navbar>
        <div class="detail">
            <div class="wrap">
                <div class="repair_form">
                    <van-row type="flex">
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="order_name"
                                label="工单名称:"
                                name="service_name"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="name"
                                label="联系人:"
                                name="name"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="phone"
                                label="电话:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>                         
                        <van-col span="11">
                            <van-field
                                readonly
                                v-model="area"
                                label="区域:"
                                name="area"
                                class="repair_input"
                            >                                
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11" offset="2">
                            <van-field
                                readonly
                                v-model="building"
                                label="楼号:"
                                name="building"
                                class="repair_input"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11">
                            <van-field
                                readonly
                                v-model="floor"
                                label="楼层:"
                                name="floor"
                                class="repair_input"
                            >                                
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11" offset="2">
                            <van-field
                                readonly
                                v-model="room"
                                label="房号:"
                                name="room"
                                class="repair_input"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                    </van-row>
                </div>
                <div class="repair_form">
                    <van-row>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="repair_name"
                                label="报修名称:"
                                name="time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                rows="3"
                                autosize
                                type="textarea"
                                v-model="content"
                                label="问题描述:"
                                name="content"
                                :rules="[{ required: true, message: '请输入您的需求' }]"
                                class="repair_input repair_textarea"
                            />
                        </van-col>
                        <van-col span="24">
                            <div class="photo_upload">
                                <span class="photo_left font-26 color-6">上传照片:</span>
                                <div class="photo_right">
                                    <van-uploader 
                                        v-model="photo_list"
                                        multiple
                                        :deletable = 'false'
                                        :show-upload = 'false'
                                    />
                                </div>
                            </div>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="reservation_time"
                                label="业主预约时间:"
                                name="reservation_time"
                                class="repair_input"
                            />
                        </van-col>
                    </van-row>
                </div>
                <div class="repair_form">
                    <van-row>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="acceptance_time"
                                label="受理时间"
                                name="acceptance_time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                clickable
                                v-model="process_time"
                                label="处理时间"
                                name="process_time"
                                class="repair_input"
                                @click="showPickerTime = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="finish_time"
                                label="完成时间"
                                name="finish_time"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                clickable
                                v-model="use_tool"
                                label="使用物料"
                                name="use_tool"
                                class="repair_input"
                                @click="showPickerTool = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="use_tool_price"
                                label="使用物料价格"
                                name="use_tool_price"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                v-model="labor_cost"
                                label="人工费"
                                name="labor_cost"
                                class="repair_input"
                            />
                        </van-col>                        
                    </van-row>
                    <!-- 选择时间 -->
                    <van-popup v-model="showPickerTime" round position="bottom">
                        <van-datetime-picker
                            type="datetime"                            
                            :min-date="minDate"
                            :max-date="maxDate"
                            @confirm="onConfirmTime"
                            @cancel="showPickerTime = false"
                        />
                    </van-popup>
                    <!-- 选择使用物料 -->
                    <van-popup v-model="showPickerTool" round position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="tool"
                            @confirm="onConfirmTool"
                            @cancel="showPickerTool = false"
                        />
                    </van-popup>
                </div>
                <div class="repair_form">
                    <van-row>                        
                        <van-col span="24">
                            <div class="photo_upload">
                                <span class="photo_left font-26 color-6">上传竣工照片</span>
                                <div class="photo_right">
                                    <van-uploader 
                                        v-model="finish_list"
                                        multiple
                                    />
                                </div>
                            </div>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                rows="3"
                                autosize
                                type="textarea"
                                v-model="idea"
                                label="处理意见:"
                                name="idea"
                                class="repair_input repair_textarea"
                            />
                        </van-col>
                    </van-row>
                </div>
                <div class="bot_height">
                    <div class="bot_button flex justify-between align-center">
                        <van-button class="custom_btn cancel_btn" round block>取消</van-button>
                        <van-button class="custom_btn gradual" round block>确认</van-button>
                        <!-- <van-button class="custom_btn plain_btn" round block>加急工单</van-button> -->
                        <!-- <van-button class="custom_btn gradual" round block>立即执行</van-button> -->
                        <!-- <van-button class="custom_btn gradual" round block>竣工</van-button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'reservation_detail',
    data () {
        return {
            order_name: '水电维修',
            repair_name: '厨房没电',
            name: '张伟',
            phone: '13498573847',
            area: '南区',
            building: '5号',
            floor: '10层',
            room: '1023号',
            columns: ['区域1', '区域2', '区域3', '区域4', '区域5'],
            showPickerArea: false, // 区域、楼号、楼层、房号选择
            content: '这是一段描述',
            photo_list: [
                { url: require('@/assets/upload/photo.png') },
                { url: require('@/assets/upload/photo.png') }
            ],
            reservation_time: '2023-2-13  8:00~10:00',
            acceptance_time: '2023-2-13  8:30',
            process_time: '2023-2-13  8:30',
            finish_time: '2023-2-13  8:30',
            showPickerTime: false, //时间选择
            minDate: new Date(2023, 1, 1),
            maxDate: new Date(2025, 10, 1),
            use_tool: '灯具',
            tool: ['灯具', '螺丝', '测试1', '测试2', '测试3'],
            showPickerTool: false,
            use_tool_price: '¥23.89',
            labor_cost: '¥23.89',            
            finish_list: [
                { url: require('@/assets/upload/photo.png') }
            ],
            idea: '这是一段主管或经理的批注意见…'
        }
    },
    methods: {
        onConfirmTime(values){
            let that = this ;
            this.time = that.utils.formatTime(values,'-',5)
            this.showPickerTime = false;
        },
        onConfirmTool(values) {
            this.use_tool = values
            this.showPickerTool = false;
        },
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.detail{    
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
}
.repair_form{
    padding: .3rem .2rem;
    border-radius: .1rem;
    margin-bottom: .3rem;
    background-color: #FFFFFF;
}
.repair_name{
    line-height: 1.4;
    padding-bottom: .2rem;
    font-weight: normal;
}
.repair_input{
    display: block;
    font-size: .28rem;
    color: #666666;
    line-height: .8rem;
    padding: 0;
    margin-bottom: .4rem;
    &:active{
        background-color: transparent;
    }
    ::v-deep .van-field__label{
        width: 100%;
        line-height: 2;
        margin: .1rem 0;
        color: #666666;
    }
    ::v-deep .van-field__body{
        width: 100%;
        height: .8rem;
        border-radius: .08rem;
        border: .02rem solid #EEEEEE;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .76rem;
        padding: 0 .2rem;
        border-radius: .08rem;
    }
    ::v-deep .van-field__control:disabled{
        color: #999999;
        -webkit-text-fill-color: #999999;
    }
    ::v-deep .van-field__button{
        padding-right: .1rem;
    }
    ::v-deep .van-field__button img{
        width: .32rem;
        height: .32rem;
        object-fit: contain;
        vertical-align: middle;
    }
    ::v-deep .van-field__error-message{
        font-size: .2rem;
        line-height: 1.4;
        margin-top: .1rem;
        padding: 0 .2rem;
    }
}
.repair_textarea{
    ::v-deep .van-field__body{
        width: 100%;
        height: auto;
        border-radius: .08rem;
        border: .02rem solid #DFDFDF;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .4rem;
        padding: .2rem;
        border-radius: .08rem;
    }
}
.pending ::v-deep .van-field__control{
    color: #00B0F0;
}
.processing ::v-deep .van-field__control{
    color: #39DCAB;
}
.evaluated ::v-deep .van-field__control{
    color: #F8B142;
}
.photo_upload{
    margin-bottom: .4rem;
    .photo_left{
        display: block;
        line-height: 2;
        margin-bottom: .15rem;
    }
    .photo_right{
        ::v-deep .van-uploader{
            margin-bottom: -.46rem;
        }
        ::v-deep .van-uploader__preview{
            margin-right: .46rem;
            margin-bottom: .46rem;
        }
        ::v-deep .van-uploader__preview-image{
            width: 1.6rem;
            height: 1.6rem;
        }
        ::v-deep .van-uploader__preview-delete{
            top: -.16rem;
            right: -.16rem;
            width: .32rem;
            height: .32rem;
            border-radius: 50%;
            background-color: #4E9DF6;
        }
        ::v-deep .van-uploader__preview-delete-icon{
            font-size: .32rem;
            top: 0;
            right: 0;
            transform: scale(0.6);
        }
        ::v-deep .van-uploader__upload{
            width: 1.6rem;
            height: 1.6rem;
            margin-right: .46rem;
            margin-bottom: .46rem;
        }
        ::v-deep .van-uploader__upload-icon{
            font-size: .64rem;
        }
    }
    .photo_default{
        width: 1.6rem;
        height: 1.6rem;
        border-radius: .08rem;
        border: .02rem solid #D4D5D6;
        img{
            width: .56rem;
            height: .56rem;
            object-fit: contain;
            margin: 0 auto .1rem;
        }
    }
} 

.status_box{
    line-height: 2;
    padding: .3rem .2rem;
    margin-bottom: .3rem;
    border-radius: .1rem;
    background-color: #FFFFFF;
    .repair_input{
        margin-bottom: 0;
    }
}
.star{
    margin: .1rem 0 .25rem;
    ::v-deep .van-rate__item{
        margin-right: .4rem;
    }
    ::v-deep .van-rate__icon{
        width: .48rem;
        height: .48rem;
        font-size: 0;
    }
    ::v-deep .van-icon__image{
        width: 100%;
        height: 100%;
    }
}
.bot_height{
    height: 1.5rem;
    height: calc(1.5rem + constant(safe-area-inset-bottom));
    height: calc(1.5rem + env(safe-area-inset-bottom));
}
</style>