import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { Col, Row, Button, NavBar, Tabbar, TabbarItem, Icon, Swipe, SwipeItem, Slider, Cell, CellGroup, RadioGroup, Radio, Checkbox, CheckboxGroup, Form, Field, Popup, Picker, Area, PullRefresh, List, NoticeBar, Sticky, Tab, Tabs, Toast, Empty, Uploader, DatetimePicker,TreeSelect, Rate, Search  } from 'vant';

Vue.use(Col);
Vue.use(Row);
Vue.use(Button)
Vue.use(NavBar)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Icon)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Slider)
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Form)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Area)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(NoticeBar)
Vue.use(Sticky)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Toast)
Vue.use(Empty)
Vue.use(Uploader)
Vue.use(DatetimePicker);
Vue.use(TreeSelect);
Vue.use(Rate);
Vue.use(Search);

import vueWechatTitle from 'vue-wechat-title'
Vue.use(vueWechatTitle)

import Navbar from './components/Navbar'
Vue.component('navbar', Navbar)

Vue.prototype.btColor = 'linear-gradient(127deg, #b0c0ff 0%, #4d6aff 100%)'

Vue.prototype.$getDays = function(num){
  var date = new Date();

  date.setDate(date.getDate() + num)

  let year = date.getFullYear()				//年
  let month = date.getMonth() + 1				//月
  let day = date.getDate();					//天数
  if(month >= 1 && month <= 9) {
    month = `0${month}`
  }
  if(day >= 1 && day <= 9) {
    day = `0${day}`
  }
  return `${year}-${month}-${day}`
}


import utils from './utils/utils';
Vue.prototype.utils = utils

import './style/reset.scss'
import './style/common.scss'
import './utils/rem'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

