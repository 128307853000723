<template>
    <div class="container">
        <navbar title="我的" :leftArrow="false"></navbar>
        <div class="my_box">
            <div class="wrap">
                <div class="my_top_bg">
                    <div class="my_top flex align-center justify-between">
                        <div class="my_avatar">
                            <img src="@/assets/upload/avatar.png" alt="">
                        </div>
                        <div class="my_right">
                            <h2 class="my_position font-36 ellipsis">中国气象科技园区运维</h2>
                            <div class="my_name font-24 color-3">张三将 <span class="color-m">136****5678</span></div>
                        </div>
                        <div class="my_set"><img src="@/assets/icon/set.png" alt="设置"></div>
                    </div>
                    <div class="my_info">
                        <h2 class="my_info_tit font-30">我的信息</h2>
                        <ul class="my_info_list flex flex-wrap text-center">
                            <li>
                                <router-link to="/">
                                    <div class="my_info_icon">
                                        <img src="@/assets/icon/my_info_01.png" alt="我的账单">
                                    </div>
                                    <h3 class="my_info_name font-24 color-6">我的账单</h3>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/">
                                    <div class="my_info_icon">
                                        <img src="@/assets/icon/my_info_02.png" alt="我的访客">
                                    </div>
                                    <h3 class="my_info_name font-24 color-6">我的访客</h3>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/">
                                    <div class="my_info_icon">
                                        <img src="@/assets/icon/my_info_03.png" alt="我的收据">
                                    </div>
                                    <h3 class="my_info_name font-24 color-6">我的收据</h3>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/">
                                    <div class="my_info_icon">
                                        <img src="@/assets/icon/my_info_04.png" alt="投诉建议">
                                    </div>
                                    <h3 class="my_info_name font-24 color-6">投诉建议</h3>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/">
                                    <div class="my_info_icon">
                                        <img src="@/assets/icon/my_info_05.png" alt="进出申请">
                                    </div>
                                    <h3 class="my_info_name font-24 color-6">进出申请</h3>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/">
                                    <div class="my_info_icon">
                                        <img src="@/assets/icon/my_info_06.png" alt="我的预约">
                                    </div>
                                    <h3 class="my_info_name font-24 color-6">我的预约</h3>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="my_list font-28 color-6">
                    <li>
                        <div class="flex align-center">
                            <img src="@/assets/icon/my_list_01.png" alt="">
                            <span>清除缓存</span>
                            <van-icon name="arrow" color="#CCCCCC" />
                        </div>
                    </li>
                    <li>
                        <div class="flex align-center">
                            <img src="@/assets/icon/my_list_02.png" alt="">
                            <span>隐私设置</span>
                            <van-icon name="arrow" color="#CCCCCC" />
                        </div>
                    </li>
                    <li>
                        <router-link to="/" class="flex align-center">
                            <img src="@/assets/icon/my_list_03.png" alt="">
                            <span>新消息通知</span>
                            <van-icon name="arrow" color="#CCCCCC" />
                        </router-link>
                    </li>
                </ul>
                <div class="my_tel flex justify-center align-center">
                    <img src="@/assets/icon/my_tel.png" alt="">
                    <div class="my_tel_right font-32 color-6">
                        <p>400-8581-8848</p>
                        <p class="font-24 color-9">在线时间：9：00-18：00</p>
                    </div>
                </div>
                <van-button class="custom_btn exit_btn" round block>退出登录</van-button>
            </div>
        </div>
        <Tabbar :current="3"></Tabbar>
    </div>
</template>
<script>
import Tabbar from '@/components/Tabbar.vue'
export default {
    name: 'my',
    components: { Tabbar },
    data () {
        return {
        }
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
    .my_box{
        background: url('~@/assets/bg/my_top_bg.png') center top no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    .my_top_bg{
        margin-bottom: .3rem;
    }
    .my_top{
        padding: .2rem 0;
        .my_avatar{
            flex-shrink: 0;
            width: 1.28rem;
            height: 1.28rem;
            border-radius: 50%;
            padding: .04rem;
            background-color: #FFFFFF;
            margin-right: .3rem;
            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .my_right{
            flex: auto;
            overflow: hidden;
            .my_position{
                color: #272727;
                line-height: 2;
            }
            .my_name{
                font-weight: bold;
                span{
                    margin-left: .24rem;
                    font-weight: normal;
                }
            }
        }
        .my_set{
            flex-shrink: 0;
            margin-left: .2rem;
            width: .34rem;
            cursor: pointer;
            img{
                width: 100%;
                height: auto;
            }
        }
    }
    .my_info{
        border-radius: .08rem;
        background-color: #FFFFFF;
        overflow: hidden;
        .my_info_tit{
            color: #101216;
            margin: .3rem;
        }
        .my_info_list{
            list-style: none;
            li{
                width: 25%;
                padding: 0 .3rem;
                margin-bottom: .3rem;
                a{
                    display: block;
                }
                .my_info_icon{
                    width: auto;
                    height: .36rem;
                    margin: 0 auto .14rem;
                    img{
                        width: auto;
                        height: 100%;
                    }
                }
                .my_info_name{
                    line-height: 1.4;
                    font-weight: normal;
                }
            }
        }
    }
    .my_list{
        list-style: none;
        background-color: #FFFFFF;
        border-radius: .08rem;
        margin: .3rem 0;
        li{
            line-height: .4rem;
            padding: .3rem;
            cursor: pointer;
            img{
                width: .4rem;
                height: .4rem;
                object-fit: contain;
                margin-right: .1rem;
            }
            span{
                flex: auto;
                overflow: hidden;
            }
            .vant-icon{
                flex-shrink: 0;
            }
        }
    }
    .my_tel{
        margin: .8rem 0 1.2rem;
        img{
            width: .6rem;
            height: .6rem;
            margin-right: .2rem;
            object-fit: contain;
        }
        .my_tel_right{
            overflow: hidden;
        }
    }
    .exit_btn{
        color: #666666;
        background: #EBEBEB;
        border-radius: .08rem;
        margin: 1.2rem auto .4rem;
    }
</style>