import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import MerchantsList from '@/views/merchants/list.vue'
import MerchantsDetail from '@/views/merchants/detail.vue'
import Visitor from '@/views/visitor/visitor.vue'
import Login from '@/views/login/login.vue'
import Password from '@/views/login/password.vue'
import NoticeList from '@/views/notice/list.vue'
import NoticeDetail from '@/views/notice/detail.vue'
import My from '@/views/my/my.vue'
import Information from '@/views/my/information.vue'
import Feedback from '@/views/my/feedback.vue'
import MyVisitors from '@/views/my/my_visitors.vue'
import Receipt from '@/views/my/receipt.vue'
import ReceiptDetail from '@/views/my/receipt_detail.vue'
import Bill from '@/views/my/bill.vue'
import BillDetail from '@/views/my/bill_detail.vue'
import Reservation from '@/views/my/reservation.vue'
import ReservationDetail from '@/views/my/reservation_detail.vue'
import ReservationEvaluated from '@/views/my/reservation_evaluated.vue'
import Apply from '@/views/my/apply.vue'
import ApplyDetail from '@/views/my/apply_detail.vue'
import Repair from '@/views/repair/repair.vue'
import RepairSuccess from '@/views/repair/repair_success.vue'
import Booking from '@/views/booking/booking.vue'
import bookingForm from '@/views/booking/booking_form.vue'
// 物业端-property
import Property from '@/views/property/property.vue'
import PropertyNews from '@/views/property/news.vue'
import PropertyNewsDetail from '@/views/property/news_detail.vue'
import PropertyOrder from '@/views/property/order.vue'
import PropertyOrderDetail from '@/views/property/order_detail.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: '登录' }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: '中国气象科技园区物业运维公众号' }
  },
  {
    path: '/merchants_list',
    name: 'MerchantsList',
    component: MerchantsList,
    meta: { title: '园区招商' }
  },
  {
    path: '/merchants_detail',
    name: 'MerchantsDetail',
    component: MerchantsDetail,
    meta: { title: '招商详情' }
  },
  {
    path: '/visitor',
    name: 'Visitor',
    component: Visitor,
    meta: { title: '访客通行' }
  },
  {
    path: '/password',
    name: 'Password',
    component: Password,
    meta: { title: '忘记密码' }
  },
  {
    path: '/notice_list',
    name: 'NoticeList',
    component: NoticeList,
    meta: { title: '公告' }
  },
  {
    path: '/notice_detail',
    name: 'NoticeDetail',
    component: NoticeDetail,
    meta: { title: '公告详细' }
  },
  {
    path: '/my',
    name: 'My',
    component: My,
    meta: { title: '我的' },
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
    meta: { title: '基本信息' }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
    meta: { title: '问题反馈' }
  },
  {
    path: '/my_visitors',
    name: 'MyVisitors',
    component: MyVisitors,
    meta: { title: '我的访客' }
  },
  {
    path: '/receipt',
    name: 'Receipt',
    component: Receipt,
    meta: { title: '我的收据' }
  },
  {
    path: '/receipt_detail',
    name: 'ReceiptDetail',
    component: ReceiptDetail,
    meta: { title: '缴费收据' }
  },
  {
    path: '/bill',
    name: 'Bill',
    component: Bill,
    meta: { title: '我的账单'}
  },
  {
    path: '/bill_detail',
    name: 'BillDetail',
    component: BillDetail,
    meta: { title: '我的账单详情'}
  },
  {
    path: '/reservation',
    name: 'Reservation',
    component: Reservation,
    meta: { title: '我的预约'}
  },
  {
    path: '/reservation_detail',
    name: 'ReservationDetail',
    component: ReservationDetail,
    meta: { title: '预约详情'}
  },
  {
    path: '/reservation_evaluated',
    name: 'ReservationEvaluated',
    component: ReservationEvaluated,
    meta: { title: '预约评价'}
  },
  {
    path: '/apply',
    name: 'Apply',
    component: Apply,
    meta: { title: '进出门申请'}
  },
  {
    path: '/apply_detail',
    name: 'ApplyDetail',
    component: ApplyDetail,
    meta: { title: '进出门申请详情'}
  },  
  {
    path: '/repair',
    name: 'Repair',
    component: Repair,
    meta: { title: '设备报修' }
  },
  {
    path: '/repair_success',
    name: 'RepairSuccess',
    component: RepairSuccess,
    meta: { title: '设备报修成功' }
  },
  {
    path: '/booking',
    name: 'Booking',
    component: Booking,
    meta: { title: '预约' }
  },
  {
    path: '/booking_form',
    name: 'bookingForm',
    component: bookingForm,
    meta: { title: '预约表单' }
  },
  // 物业端
  {
    path: '/property',
    name: 'Property',
    component: Property,
    meta: { title: '物业端' }
  },
  {
    path: '/property_news',
    name: 'PropertyNews',
    component: PropertyNews,
    meta: { title: '物业端-消息' }
  },
  {
    path: '/property_news_detail',
    name: 'PropertyNewsDetail',
    component: PropertyNewsDetail,
    meta: { title: '物业端-消息-详情' }
  },
  {
    path: '/property_order',
    name: 'PropertyOrder',
    component: PropertyOrder,
    meta: { title: '物业端-工单' }
  },
  {
    path: '/property_order_detail',
    name: 'PropertyOrderDetail',
    component: PropertyOrderDetail,
    meta: { title: '物业端-工单-详情' }
  },
  
  
  
  
  
  
 
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})


/*function delCodeandstate(to) { //函数作用：去除url中的code和state
  let path = ""
  for (let i in to.query) {
    if (i != "code" && i != "state") {
      path = path + "&" + i + "=" + to.query[i]
    }
  }
  path = path == "" ? "" : path.substring(1, path.length)
  path = path == "" ? "" : "/?" + path
  return path;
}

router.beforeEach((to, from, next) => {
  let fullPath = to.fullPath
  if (to.fullPath.includes("code")) { //判断url中是否有code，踩坑1-页面反复跳转
    fullPath = delCodeandstate(to)
  }
  let redirect_uri = encodeURIComponent("http://ql.1092cc.cn/api" + fullPath), appid = "wx347e34c766eec20d" //redirect_uri，授权登陆后的回调地址，需要进行encodeURIComponent处理
  let code = to.query.code, state = to.query.state
  let noLoginArr = ["/"], isCur = false, token = sessionStorage.getItem("token") //noLoginArr，白名单，不需要授权登陆的页面
  for (let i of noLoginArr) {
    if (to.path == i) {
      isCur = true
    }
  }
  if (isCur) { //白名单内不做登录判断，直接next
    next()
  } else {
    if (code && state && !token) { //登陆之后获取到code，传到后台登录
      let data = {
        code: code
      }
      axios.post('/api/auth/code', data).then((res) => {
        if (res.code == 200) {
          sessionStorage.setItem("token", res.data.token)
          axios.defaults.headers.common['token'] = res.data.token
          next()
        } else if (res.code == 401) { //后台判断toke是否失效，失效返回401重新授权登陆
          //去登录
          window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
        }
      }).catch(function (error) {
        Toast.fail(error.message)
      });
    } else if (token) { //已登录，有token，判断是否过期
      axios.defaults.headers.common['token'] = sessionStorage.getItem("token")
      let data = {
        token: token
      }
      axios.post('/api/auth/checkToken', data).then((res) => { //判断token是否过期接口
        if (res.code == 200) {
          next()
        } else if (res.code == 401) { //后台判断toke是否过期，过期返回401重新授权登陆
          sessionStorage.setItem("token","")
          axios.defaults.headers.common['token'] = ""
          //去登录
          window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
        }
      }).catch(function (error) {
        Toast.fail(error.message)
      });
    } else { //未登录，没有token，去登录
      //去登录
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
    }
  }
})*/


export default router
