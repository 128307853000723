<template>
    <div class="container">
        <navbar title="预约" bgColor="#F7F7F7"></navbar>
        <div class="booking_box">
            <div class="wrap">
                <van-form @submit="onSubmit" class="repair_form">
                    <van-row type="flex">
                        <van-col span="24">
                            <van-field
                                v-model="username"
                                label="姓名:"
                                name="name"
                                placeholder="请输入姓名"
                                autocomplete="off"
                                :rules="[{ required: true, message: '请输入您的姓名' }]"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="24">
                            <van-field
                                v-model="mobile"
                                label="电话:"
                                name="mobile"
                                autocomplete="off"
                                placeholder="请输入联系方式"
                                :rules="[{ required: true, message: '请输入您的联系方式' }]"
                                class="repair_input"
                            />
                        </van-col>
                        <van-col span="11">
                            <van-field
                                readonly
                                clickable
                                v-model="districtName"
                                label="区域:"
                                name="districtName"
                                :rules="[{ required: true, message: '请选择区域：' }]"
                                class="repair_input"
                                @click="showPickerDistrict = true"
                            >                                
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11" offset="2">
                            <van-field
                                readonly
                                clickable
                                v-model="buildingName"
                                label="楼号:"
                                name="name"
                                :rules="[{ required: true, message: '请选择楼号：' }]"
                                class="repair_input"
                                @click="showPickerBuilding = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11">
                            <van-field
                                readonly
                                clickable
                                v-model="floorName"
                                label="楼层:"
                                name="name"
                                :rules="[{ required: true, message: '请选择楼层：' }]"
                                class="repair_input"
                                @click="showPickerFloor = true"
                            >                                
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="11" offset="2">
                            <van-field
                                readonly
                                clickable
                                v-model="roomName"
                                label="房号:"
                                name="name"
                                :rules="[{ required: true, message: '请选择房号：' }]"
                                class="repair_input"
                                @click="showPickerRoom = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                clickable
                                v-model="typeName"
                                label="预约项目:"
                                name="name"
                                :rules="[{ required: true, message: '请输入选择预约项目' }]"
                                class="repair_input"
                                @click="showPickerType = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                        <van-col span="24">
                            <van-field
                                readonly
                                clickable
                                v-model="appointment_time"
                                label="预约时间:"
                                name="name"
                                placeholder="请选择预约时间"
                                :rules="[{ required: true, message: '请选择您想要的上门时间：' }]"
                                class="repair_input"
                                @click="showPickerTime = true"
                            >
                                <template #button>
                                    <img src="@/assets/icon/select.png" alt="">
                                </template>
                            </van-field>
                        </van-col>
                    </van-row>
                    <!-- 选择区域、楼号、楼层、序号 -->
                    <van-popup v-model="showPickerDistrict" round position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="district"
                            @confirm="onConfirmDistrict"
                            @cancel="onCancelDistrict"
                        >
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.name }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>
                    <van-popup v-model="showPickerBuilding" round position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="building"
                            @confirm="onConfirmBuilding"
                            @cancel="onCancelBuilding"
                        >
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.name }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>
                    <van-popup v-model="showPickerFloor" round position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="floor"
                            @confirm="onConfirmFloor"
                            @cancel="onCancelFloor"
                        >
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.name }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>
                    <van-popup v-model="showPickerRoom" round position="bottom">
                        <van-picker
                            show-toolbar
                            :columns="room"
                            @confirm="onConfirmRoom"
                            @cancel="onCancelRoom"
                        >
                            <template #option="option">
                                <div style="display: flex; flex-direction: column; align-items: center;">
                                    <div>{{ option.name }}</div>
                                </div>
                            </template>
                        </van-picker>
                    </van-popup>
                    <!-- 选择时间 -->
                    <van-popup v-model="showPickerTime" round position="bottom">
                        <van-datetime-picker
                            type="datetime"                            
                            :min-date="minDate"
                            :max-date="maxDate"
                            @confirm="onConfirmTime"
                            @cancel="showPickerTime = false"
                        />
                    </van-popup>
                    <!-- 选择维修类型 -->
                    <van-popup v-model="showPickerType" round position="bottom">
                        <van-tree-select
                            height="5.95rem"
                            :items="projectType"
                            :main-active-index.sync="typeIndex"
                            @click-nav="leftType"
                            @click-item="rightType"
                            class="custom_tree"
                        >
                        </van-tree-select>
                    </van-popup>
                    <div class="bot_button">
                        <van-button class="custom_btn login_btn" round block type="info" native-type="submit">立即预约</van-button>
                    </div>
                </van-form>
            </div>            
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import booking from '@/api/booking'
export default {
    name: 'booking_form',
    data () {
        return {
            username: '',
            mobile: '',
            district_id: '',
            districtName: '',
            district: [],
            building: [],
            buildingName: '',
            building_id: '',
            floor: [],
            room: [],
            floor_id: '',
            floorName: '',
            room_id: '',
            roomName: '',
            repair_name: '',
            appointment_time: '', //上门时间
            type: '',
            typeName: '',
            isParts: '1',
            content:'',
            photo:[],
            showPickerDistrict: false,
            showPickerBuilding: false, // 区域、楼号、楼层、房号选择
            showPickerFloor: false,
            showPickerRoom: false,
            showPickerTime: false, //上门时间选择
            minDate: new Date(2023, 1, 1),
            maxDate: new Date(2025, 10, 1),
            showPickerType: false, //维修类型
            typeIndex: 0,
            projectType: [],
            root: [],
            tableSelect: [],

            radioIcon: require('@/assets/icon/check_blue.png'),
            radioIcon2: require('@/assets/icon/check_gray.png'),
        }
    },
    computed: {
        ...mapGetters(['token'])
    },
    methods: {
        onConfirmDistrict(value, index) {
            this.showPickerDistrict = false
            this.district_id = this.district[index].id
            this.districtName = this.district[index].name
        },
        onCancelDistrict() {
            this.showPickerDistrict = false
        },
        onConfirmBuilding(value, index) {
            this.showPickerBuilding = false
            this.building_id = this.building[index].id
            this.buildingName = this.building[index].name
        },
        onCancelBuilding() {
            this.showPickerBuilding = false
        },
        onConfirmFloor(value, index) {
            this.showPickerFloor = false
            this.floor_id = this.floor[index].id
            this.floorName = this.floor[index].name
        },
        onCancelFloor() {
            this.showPickerFloor = false
        },
        onConfirmRoom(value, index) {
            this.showPickerRoom = false
            this.room_id = this.room[index].id
            this.roomName = this.room[index].name
        },
        onCancelRoom() {
            this.showPickerRoom = false
        },
        onConfirmTime(values){
            let that = this ;
            this.appointment_time = that.utils.formatTime(values,'-',5)
            this.showPickerTime = false;
        },
        onConfirmType(){
            this.showPickerType = false;
            console.log(22)
        },
        // 维修类型左
        leftType(index){
            this.typeIndex = index
        },
        // 维修类型右
        rightType(data){
            this.typeName = data.text
            this.typeIndex = data.activeId
            console.log(this.type + this.typeName)
            this.showPickerType = false;
        },
        getDistrict() {
            booking.district().then((res) => {
                    if (res.status === 0) {
                        this.district = res.data
                    }
            })
        },
        getBuilding() {
            booking.building().then((res) => {
                if (res.status === 0) {
                    this.building = res.data
                }
            })
        },
        getFloor() {
            booking.floor().then((res) => {
                if (res.status === 0) {
                    this.floor = res.data
                }
            })
        },
        getRoom() {
            booking.room().then((res) => {
                if (res.status === 0) {
                    this.room = res.data
                }
            })
        },
        getRoot() {
            booking.root().then((res) => {
                if (res.status === 0) {
                    res.data.forEach(v => {
                        this.root.push(
                            {
                                text: v.name,   // items里面的对象属性必须是text和activeId
                                activeId: v.id
                            }
                        )
                    })
                }
            })
        },
        onNavClick (index) {
            const id = this.root[index].activeId // 点击分类传入当前下标获取该分类的ID

            booking.subType({pid: id}).then((res) => {
                if (res.status === 0) {
                    this.tableSelect = res.data
                }
            })
        },

        getProjectType() {
            booking.projectType().then((res) => {
                if (res.status === 0) {
                    this.projectType = res.data
                }
            })
        },
        onSubmit() {
            let that = this
            this.params = {
                type: this.type,
                username: this.username,
                mobile: this.mobile,
                district_id: this.district_id,
                building_id: this.building_id,
                floor_id: this.floor_id,
                room_id: this.room_id,
                project_type_id: this.typeIndex ?? 0,
                appointment_time: this.appointment_time
            }

            booking.submit(this.params).then((res) => {
                if (res.status === 0) {
                    this.$toast('提交成功')
                    setTimeout(function() {
                        that.$router.go(-1);
                    }, 500)
                } else {
                    this.$toast('提交失败')
                }
            })
        }
    },
    created () {
        this.type = this.$route.query.type
        if (!this.token) {
            this.$toast.fail('你还未登录')
            setTimeout(function() {
                window.location.href = '/login'
            }, 500)
            return false
        }
        this.getRoot()
        this.getDistrict()
        this.getBuilding()
        this.getFloor()
        this.getRoom()
        this.getProjectType()

    },
    mounted () {
    },
}
</script>
<style lang="scss" scoped>
.booking_box{
    padding-bottom: 1.8rem;
    padding-bottom: calc(1.8rem + constant(safe-area-inset-bottom));
    padding-bottom: calc(1.8rem + env(safe-area-inset-bottom));
}
.repair_form{
    padding: .3rem .2rem;
    border-radius: .1rem;
    margin-bottom: .3rem;
    background-color: #FFFFFF;
}
.repair_name{
    line-height: 1.4;
    padding-bottom: .2rem;
    font-weight: normal;
}
.repair_input{
    display: block;
    font-size: .28rem;
    color: #666666;
    line-height: .8rem;
    padding: 0;
    margin-bottom: .4rem;
    &:active{
        background-color: transparent;
    }
    ::v-deep .van-field__label{
        width: 100%;
        line-height: 2;
        margin: .1rem 0;
        color: #666666;
    }
    ::v-deep .van-field__body{
        width: 100%;
        height: .8rem;
        border-radius: .08rem;
        border: .02rem solid #EEEEEE;
        background: #FFFFFF;
    }
    ::v-deep .van-field__control{
        line-height: .76rem;
        padding: 0 .2rem;
        border-radius: .08rem;
    }
    ::v-deep .van-field__control:disabled{
        color: #999999;
        -webkit-text-fill-color: #999999;
    }
    ::v-deep .van-field__button{
        padding-right: .1rem;
    }
    ::v-deep .van-field__button img{
        width: .32rem;
        height: .32rem;
        object-fit: contain;
        vertical-align: middle;
    }
    ::v-deep .van-field__error-message{
        font-size: .2rem;
        line-height: 1.4;
        margin-top: .1rem;
        padding: 0 .2rem;
    }
}
</style>